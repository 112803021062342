import React from "react"

/**
 * This component is for helper text
 *
 * @version 1.0.1
 * @visibleName Helper
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
export const emailNonEditable = "Obs: Denne er ikke redigerbar"

export const EmailSupportFeide = () => {
  return (
    <p>
      <p>
        <p className="pt-4">
          <p className="text-md font-semibold">Stemmer ikke skolen?</p>
          <p className="text-s">
            <a href="https://www.feide.no/brukerstotte" className="underline">Ta kontakt</a>{" "} før du bestiller,
            slik at boka kommer frem til riktig sted.
          </p>
        </p>
      </p>
    </p>
  )
}

export const EmailSupportTibet = () => {
  return (
    <p className="pt-4 text-m">
      Stemmer ikke skolen eller adressen? <br />{" "}
      <a href="mailto:support@gyldendal.no" className="underline">
        Ta kontakt
      </a>{" "}
      før du bestiller, slik at boka kommer frem til riktig sted.{" "}
    </p>
  )
}

export const EmailSupportTibetEdit = () => {
  return (
    <p className="pt-4 text-m">
      Finner du ikke skolen din? Eller er det feil navn på skolen? <br /> Ta kontakt med{" "}
      <a href="mailto:support@gyldendal.no" className="underline">
        support@gyldendal.no
      </a>{" "}
    </p>
  )
}
