import React, { useEffect, useState } from "react"
import { fetchApi } from "../Common/WebApi-utils"
import MyOrdersData from "./MyOrdersData"
import MessageModal from "../Modals/MessageModal"

/**
 * This component is for listing my order history from Dynamics365
 *
 * @version 1.0.1
 * @visibleName My Orders
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */

const MyOrders = ({ accessToken, location }) => {
  const [orderExist, setOrderExist] = useState(false)
  const [order, setOrder] = useState([])
  const [orderLine, setOrderLine] = useState([])

  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  useEffect(() => {
    const orderId = window.sessionStorage.getItem("gyldendal_minside_orderid")

    if (orderId !== null)
      fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalOrders/" + orderId, accessToken, location)
        .then((response) => {
          setOrderExist(true)
          setOrder(response.data)
          setOrderLine(response.data.orderLines)
        })
        .catch((error) => {
          if (!error.message.includes("404"))
            setErrorInfo(() => ({
              buttonText: "Ok",
              title: "Oops!",
              message: error.message,
              email: false,
              showError: true,
            }))
        })
        .finally(function () {
          // always executed
        })
  }, [])

  return (
    <div className="bg-white rounded-lg p-4 border-blue">
      {errorInfo.showError && <MessageModal {...errorInfo} />}
      <div className="relative lg:pb-2">
        {orderExist === true ? (
          <div>
            <h2 className="text-2xl font-bold my-4">Din bestilling av vurderingseksemplar er fullført!</h2>
            <p className="text-md">Ordrebekreftelse er sendt til din e-post.</p>
            <p className="text-md">Bestilling gjelder:</p>
          </div>
        ) : (
          <>
            <div></div>
          </>
        )}
      </div>
      <ul>
        {orderLine.map((data) => (
          <MyOrdersData key={data.id} data={data} />
        ))}
      </ul>
    </div>
  )
}

export default MyOrders
