import React, { ReactElement } from "react"
import TabTitle from "./TabTitle"

/**
 * This component is for creating the tabs injected in the finalpage.tsx
 *
 * @version 1.0.2
 * @visibleName Tabs
 * @author [Younes Hajji]
 * @author [Joachim Nordholmen]
 */

export const tabs = [
    { name: 'Mine tilganger', href: '/app/tilganger', current: false },
    { name: 'Profil & innstillinger', href: '/app/instillinger', current: false },
]

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}