import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { navigate } from "@reach/router"

/**
 * This component is for buttons
 *
 * @version 1.0.1
 * @visibleName Buttons
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 * @author [Joachim Nordholmen]
 */

export type ButtonType = {
  onClick?: () => void
  text?: string
  disabled?: boolean
  size?: string
}

export const PrimaryButton = (props: ButtonType) => {
  const [disabled, setDisabled] = useState(props.disabled)

  const size = props.size ? props.size : ""

  useEffect(() => {
    setDisabled(props.disabled)
  }, [props.disabled])

  return (
    <button
      disabled={disabled}
      onClick={props.onClick}
      className={"flex items-center justify-center px-4 py-2 bg-red-600 shadow rounded-md hover:bg-pink-700 " + size}
    >
      <p className="text-base font-semibold leading-normal text-white w-full whitespace-nowrap">{props.text}</p>
    </button>
  )
}
export const PrimaryWhiteButton = (props: ButtonType) => {
  const [disabled, setDisabled] = useState(props.disabled)

  useEffect(() => {
    setDisabled(props.disabled)
  }, [props.disabled])

  return (
    <button
      disabled={disabled}
      onClick={props.onClick}
      className="inline-flex items-center justify-center px-4 py-2 bg-white shadow border rounded-md border-gray-300 hover:bg-gray-50 "
    >
      <p className="text-sm font-semibold leading-tight text-gray-700">{props.text}</p>
    </button>
  )
}

export const LinkButton = (link) => {
  return <Link to={link} />
}

export const DisabledButton = ({ disabledWhen, text, link, onClick }) => {
  return (
    <button
      onClick={() => {
        onClick
        navigate(link)
      }}
      disabled={disabledWhen ? false : true}
      className="h-10 m-2 bg-carmine-500 hover:bg-carmine-800 text-white font-semibold hover:text-white py-2 px-6 border border-carmine-500 rounded disabled:opacity-50 disabled:cursor-default"
    >
      {text}
    </button>
  )
}

export const CancelButton = ({ cancelMethod, cancelMode }) => {
  return (
    <span
      onClick={() => cancelMethod(cancelMode)}
      className="text-md text-black border-black w-11 p-0 h-6 border-b cursor-pointer"
    >
      Avbryt
    </span>
  )
}

export const SaveButton = ({ onClick, id }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className="h-10 w-15 bg-carmine-500 hover:bg-carmine-700 text-white font-semibold hover:text-white px-4 border border-red-500 rounded"
    >
      Lagre
    </button>
  )
}

export const EditButton = ({ setEditMode, editMode }) => {
  return (
    <span
      className="text-md text-indigo-500 h-6 px-0 border-indigo-500 w-12 border-b cursor-pointer"
      onClick={() => setEditMode(editMode)}
    >
      Endre
    </span>
  )
}
