import * as React from "react"
import "tailwindcss/tailwind.css"
import { Router } from "@reach/router"
import { withPrefix } from "gatsby"
import Home from "../components/MyPage/Home" // Startsiden til Min Side /minside/
import FormAssessmentCopy from "../components/MyPage/FormAssessmentCopy"
import FormSampleAccess from "../components/MyPage/FormSampleAccess"
import UserInfo from "../components/MyPage/UserInfo"
import IndexPage from "./index"
import ProfileSettings from "../components/MyPage/ProfileSettings"
import FinalAccesses from "../components/MyPage/FinalAccesses"

/**
 * The app file and routes
 *
 * @version 1.0.1
 * @visibleName App
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const app = () => {
  return (
    <>
      <div className="col-span-auto">
        <Router basepath={withPrefix("/app")}>
          <FormSampleAccess path="provetilgang" component={FormSampleAccess} />
          <FormAssessmentCopy path="vurderingseksemplar" component={FormAssessmentCopy} />
          <UserInfo path="brukerprofil" component={UserInfo} />
          <ProfileSettings path="instillinger" component={ProfileSettings} />
          <FinalAccesses path="tilganger" component={FinalAccesses} />
          <Home path="/hjem" component={Home} />
          <Home path="/" component={IndexPage} />
        </Router>
      </div>
    </>
  )
}

export default app
