/**
 * This component is country codes for mobilephone input
 *
 * @version 1.0.1
 * @visibleName Mobile Country
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
export const CountriesToValidate = [
  "am",
  "ae",
  "bh",
  "dz",
  "eg",
  "iq",
  "jo",
  "kw",
  "ma",
  "sa",
  "sy",
  "tn",
  "az",
  "ly",
  "lb",
  "ba",
  "by",
  "bg",
  "bd",
  "ad",
  "cz",
  "dk",
  "de",
  "at",
  "ch",
  "lu",
  "gr",
  "au",
  "ca",
  "gb",
  "gg",
  "gh",
  "hk",
  "mo",
  "ie",
  "in",
  "ke",
  "mt",
  "mu",
  "ng",
  "nz",
  "pk",
  "ph",
  "rw",
  "sg",
  "sl",
  "ug",
  "us",
  "tz",
  "za",
  "zm",
  "zw",
  "ar",
  "bo",
  "cl",
  "co",
  "cr",
  "do",
  "hn",
  "pe",
  "ec",
  "es",
  "mx",
  "pa",
  "py",
  "uy",
  "ee",
  "ir",
  "fi",
  "fj",
  "fo",
  "be",
  "ca",
  "fr",
  "gf",
  "gp",
  "mq",
  "fr",
  "ie",
  "il",
  "hu",
  "id",
  "it",
  "sm",
  "jp",
  "ge",
  "kz",
  "gl",
  "kr",
  "lt",
  "my",
  "no",
  "np",
  "be",
  "nl",
  "no",
  "pl",
  "br",
  "pt",
  "ro",
  "ru",
  "si",
  "sk",
  "al",
  "rs",
  "se",
  "th",
  "tr",
  "ua",
  "uz",
  "vn",
  "cn",
  "hk",
  "mo",
  "tw",
]
