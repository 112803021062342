import React, { useEffect, useRef, useState } from "react"
import { EmailSupportFeide } from "../Common/InfoText"
import { getOrgNumber } from "../Common/Utils"
import { fetchApi, fetchNSKApi, postApi } from "../Common/WebApi-utils"
import SchoolRadioBox from "../Graphics/SchoolRadioBox"
import MessageModal from "../Modals/MessageModal"

/**
 * This component is a dropdown for searching for accounts (schools) in Dynamics365
 *
 * @version 1.0.1
 * @visibleName Feide School dropdown
 * @author [Younes Hajji]
 */

const FeideSchoolControl = ({ setNewAccountId, queryParam }) => {
    const [open, setOpen] = useState(false)
    const [NSRFeideSchools, setNSRFeideSchools] = useState([])
    const [accounts, setAccounts] = useState([])

    const [feideSchool, setFeideSchool] = useState("0")

    const [errorInfo, setErrorInfo] = useState({
        title: "",
        message: "",
        buttonText: "",
        email: false,
        showError: false,
    })

    useEffect(() => {
        if (queryParam.usertype === "feide") {
            console.log("schoolList")
            const schoolList = queryParam.schoolNr.split(',')
            console.log(schoolList)
            // const schoolListJson = JSON.parse(searchParams.get("queryParam.schoolNr"))?.join(", ")
            // console.log("schoolList 2")
            // console.log(schoolListJson)
            schoolList.map((schoolNr, i) =>
                fetchNSKApi(getOrgNumber(schoolNr))
                    .then((response) => {
                        const feideObj = {
                            name: response.data.Navn,
                            organizationNumber: getOrgNumber(schoolNr),
                            address: response.data.Beliggenhetsadresse.Adresse,
                            zipCode: response.data.Beliggenhetsadresse.Postnr,
                            city: response.data.Beliggenhetsadresse.Poststed,
                            land: response.data.Beliggenhetsadresse.Land,
                        }

                        setNSRFeideSchools(prevState => [...prevState, feideObj])
                        // setNSRFeideSchools((prevState) => [{
                        //     ...prevState,
                        //     feideObj
                        // }])
                        console.log("schoolNr")
                        console.log(schoolNr)
                        // const crmOrgId = window.sessionStorage.getItem("gyldendal_minside_crmorgid")
                        // if (crmOrgId !== getOrgNumber(schoolNr)) {
                        const SchoolData = {
                            name: response.data.Navn,
                            organizationNumber: response.data.Orgnr,
                            address: response.data.Beliggenhetsadresse.Adresse,
                            zipCode: response.data.Beliggenhetsadresse.Postnr,
                            country: response.data.Beliggenhetsadresse.Land,
                            // city: response.data.Beliggenhetsadresse.Poststed,
                            addressPost: response.data.Postadresse.Adresse,
                            zipCodePost: response.data.Postadresse.Postnr,
                            countryPost: response.data.Postadresse.Land,
                            // cityPost: response.data.Postadresse.Poststed,
                            isPrimarySchool: response.data.ErGrunnskole,
                            isHighSchool: response.data.ErVideregaaendeSkole,
                            studentCount: response.data.Elevtall,
                            nsrNumber: schoolNr,
                        }

                        postApi(process.env.GATSBY_API_URL_APIM + "/GyldendalAccounts/getByOrgId", SchoolData, "")
                            .then((response) => {
                                // setNewAccountId(response.data.id)
                                console.log("GyldendalAccounts/getByOrgId")
                                console.log(response.data)
                                // setAccounts((prevState) => [{
                                //     ...prevState,
                                //     data: response.data
                                // }])
                                const accountObj = {
                                    name: response.data.name,
                                    organizationNumber: response.data.organizationNumber,
                                    id: response.data.id,
                                }
                                setAccounts(prevState => [...prevState, accountObj])
                            })
                            .catch((error) => {
                                if (error.message.includes("404"))
                                    setErrorInfo(() => ({
                                        title: "Oops!",
                                        message: error.message + ": Skole med org. nr. finnes ikke i CRM: " + response.data.Orgnr,
                                        buttonText: "Vi kan hjelpe deg, ta kontakt!",
                                        email: true,
                                        showError: false,
                                    }))
                                else
                                    setErrorInfo(() => ({
                                        title: "Oops!",
                                        message: error.message,
                                        buttonText: "Vi kan hjelpe deg, ta kontakt!",
                                        email: true,
                                        showError: false,
                                    }))
                            })
                            .finally(function () {
                                // always
                            })
                        // }
                    })
                    .catch((error) => {
                        setErrorInfo(() => ({
                            title: "Vi ser ikke at du er registrert med skole hos Feide.",
                            message: "Kontakt brukerstøtte Feide: https://www.feide.no/brukerstotte",
                            buttonText: "Vi kan hjelpe deg, ta kontakt!",
                            email: true,
                            showError: true,
                        }))
                    })
                    .finally(() => {
                        //always execute
                    })
            )
        }
    }, [queryParam])

    const selectOption = (school) => {
        window.sessionStorage.setItem("gyldendal_minside_accountname", school.name)
        setFeideSchool(school)
        console.log("accounts")
        console.log(accounts)
        console.log("school")
        console.log(school)
        console.log("NSRFeideSchools")
        console.log(NSRFeideSchools)
        //Find Accountid in accounts.organizationNumber by school.organizationNumber
        const account = accounts.find(obj => { return obj.organizationNumber === school })
        window.sessionStorage.setItem("gyldendal_minside_accountid", account.id)
        window.sessionStorage.setItem("gyldendal_minside_accountname", account.name)
        setNewAccountId(account.id)
    }

    return (
        <div>
            <div className="relative ">
                {errorInfo.showError && <MessageModal {...errorInfo} />}
                <div className="">
                    <SchoolRadioBox NSRFeideSchools={NSRFeideSchools} selectOption={selectOption} />
                </div>
            </div>
            <div>
                <div className="relative flex space-x-5">
                    <div className="space-y-2">
                        <EmailSupportFeide />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeideSchoolControl