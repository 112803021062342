import React, { useEffect, useState } from "react"
import UserInfo from "../MyPage/UserInfo"
import { isNullOrUndefined } from "../Common/Utils"
import { fetchApi } from "../Common/WebApi-utils"
import queryString from "query-string"
import FormAssessmentCopy from "../MyPage/FormAssessmentCopy"
import "moment-timezone"
import FormSampleAccess from "../MyPage/FormSampleAccess"
import LoadingModal from "../Modals/LoadingModal"
import moment from "moment"
import MessageModal from "../Modals/MessageModal"

/**
 * The user is redirected from the identityapi to this page after a succesfull login or SSO.
 * Ulr params are returned from the identyityapi contains an accesstoken for access Dynamics365 api based on the user
 * This component is the next step after the landing page (Index).
 * Get the contact information from Dynamics365.
 * Navigate the user further in the portal based on:
 * 1. If user is verified in Dynamics365 in the last 3 mounths, navigate directly to order page, either Vurderingseksemplar or prøvetilgang.
 * 2. If user is verified past last 3 mounths or a new user, show the verify user information page.
 *
 * @version 1.0.1
 * @visibleName Home
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const Home = () => {
  const [user, setUser] = useState([])
  const [account, setAccount] = useState([])
  const [noData, setNoData] = useState(true)
  const [updateInfo, setUpdateInfo] = useState(1)
  const [loading, setloading] = useState(false)
  const [startInfo, setStartInfo] = useState({
    sampleAccess: false,
    assessmentCopy: false,
    newUser: false,
    token: false,
  })
  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  useEffect(() => {
    setNoData(true)
    setloading(true)
    let queryParam
    if (location.search !== null) queryParam = queryString.parse(location.search)

    if (window.sessionStorage.getItem("gyldendal_minside_productid") !== "" && window.sessionStorage.getItem("gyldendal_minside_producturl") !== "") {
      setStartInfo((prevState) => ({
        ...prevState,
        sampleAccess: true,
      }))
    }

    if (window.sessionStorage.getItem("gyldendal_minside_groupid") !== "") {
      setStartInfo((prevState) => ({
        ...prevState,
        assessmentCopy: true,
      }))
    }

    //Not in use at the moment, but maybe in the future
    if (queryParam.newContact === "True") window.sessionStorage.setItem("gyldendal_minside_new_contact", "true")
    if (queryParam.newContact === "False") window.sessionStorage.setItem("gyldendal_minside_new_contact", "false")

    //Downtime maintenance
    if (queryParam.error === "410") {
      setErrorInfo(() => ({
        title: "Oops!",
        message: "Beklager, siden er nede for vedlikehold. Vennligst prøv igjen litt senere.",
        buttonText: "Vi kan hjelpe deg, ta kontakt!",
        email: true,
        showError: true,
      }))
      setNoData(false)
      setloading(false)
    }

    if (queryParam.Teacher === "false") {
      if (window.sessionStorage.getItem("gyldendal_minside_productid") !== null && window.sessionStorage.getItem("gyldendal_minside_producturl") !== null)
        setErrorInfo(() => ({
          title: "Oops",
          message: "Du forsøker å bestille et tibettilgang, men vi kan ikke se at du er registrert som lærer.",
          buttonText: "Vi kan hjelpe deg, ta kontakt!",
          email: true,
          showError: true,
        }))
      if (window.sessionStorage.getItem("gyldendal_minside_groupid") !== null)
        setErrorInfo(() => ({
          title: "Oops!",
          message: "Du forsøker å bestille et vurderingseksemplar, men vi kan ikke se at du er registrert som lærer.",
          buttonText: "Vi kan hjelpe deg, ta kontakt!",
          email: true,
          showError: true,
        }))
      else
        setErrorInfo(() => ({
          title: "Oops!",
          message: "Du forsøker å logge deg inn på minside, men vi kan ikke se at du er registrert som lærer.",
          buttonText: "Vi kan hjelpe deg, ta kontakt!",
          email: true,
          showError: true,
        }))
      setNoData(false)
      setloading(false)
    }

    if (queryParam.error === "208" || queryParam.error === "409") {
      setErrorInfo(() => ({
        title: "Oops!",
        message: "Det ser ikke ut til at vi får logget deg på.",
        buttonText: "Vi kan hjelpe deg, ta kontakt!",
        email: true,
        showError: true,
      }))
      setNoData(false)
      setloading(false)
    }

    if (queryParam.error === "400") {
      setErrorInfo(() => ({
        title: "Oops!",
        message: "Det er noe galt med kommunikasjonen mot Dynamics365.",
        buttonText: "Vi kan hjelpe deg, ta kontakt!",
        email: true,
        showError: true,
      }))
      setNoData(false)
      setloading(false)
    }

    //Get Contact
    if (queryParam.token) {
      console.log("queryParam.token")
      window.sessionStorage.setItem("gyldendal_minside_user_email", queryParam.Email)
      window.sessionStorage.setItem("gyldendal_minside_access_token", "Bearer " + queryParam.token)
      window.sessionStorage.setItem("gyldendal_minside_access_token_expire", queryParam.token_expire)
      window.sessionStorage.setItem("gyldendal_minside_usertype", queryParam.usertype)
      window.sessionStorage.setItem("gyldendal_minside_emailconfirmedat", queryParam.emailConfirmedAt)

      setStartInfo((prevState) => ({
        ...prevState,
        token: true,
      }))

      setStartInfo((prevState) => ({
        ...prevState,
        token: true,
      }))

      fetchApi(
        process.env.GATSBY_API_URL_APIM + "/GyldendalContacts?accountId=" + window.sessionStorage.getItem("gyldendal_minside_accountid")
      )
        .then((response) => {
          setUser(response.data)
          window.sessionStorage.setItem("gyldendal_minside_userfullname", response.data.fullname)
          if (response.data.account !== undefined) {
            setAccount(response.data.account)

            window.sessionStorage.setItem("gyldendal_minside_accountid", response.data.account.id)
            window.sessionStorage.setItem("gyldendal_minside_crmorgid", response.data.account.organizationNumber)
            window.sessionStorage.setItem("gyldendal_minside_accountname", response.data.account.name)
          }
          setUpdateInfo(2)
        })
        .catch((_error) => {
          setErrorInfo(() => ({
            buttonText: "Ok",
            title: "Oops!",
            message: "Får ikke lastet inn data",
            email: false,
            showError: true,
          }))
        })
        .finally(function () {
          setloading(false)
          setNoData(false)
        })
    }
  }, [])

  return (
    <>
      {loading && !startInfo.newUser ? <LoadingModal /> : null}
      <main>
        <div className={noData ? "invisible" : "justify-center py-6 sm:px-6 lg:px-8 bg-white"}>
          {errorInfo.showError && <MessageModal {...errorInfo} />}
          {(updateInfo === 2 || startInfo.newUser) && <UserInfo user={user} account={account} />}
          {startInfo.assessmentCopy && updateInfo === 3 && <FormAssessmentCopy account={account} />}
          {startInfo.sampleAccess && updateInfo === 3 && <FormSampleAccess />}
        </div>
      </main>
    </>
  )
}

export default Home
