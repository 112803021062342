import React, { useEffect, useState } from "react"
import { fetchApi } from "../Common/WebApi-utils"
import MessageModal from "../Modals/MessageModal"

/**
 * This component is for showing my order history data from Dynamics365
 * @remark This data is listed in the component "MyOrders"
 *
 * @version 1.0.1
 * @visibleName My Order data
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const MyOrdersData = ({ data }) => {
    const [material, setMaterial] = useState({
        id: "",
        iSBN: "",
        name: "",
        subtitle: "",
        vareEierStatus: "",
        language: "",
        availabilityStatus: "",
    })

    const [errorInfo, setErrorInfo] = useState({
        title: "",
        message: "",
        buttonText: "",
        email: false,
        showError: false,
    })


    useEffect(() => {
        const materialId = data.materialId
        fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalMaterials/" + materialId)
            .then((response) => {
                setMaterial((prevState) => ({
                    ...prevState,
                    id: response.data.id,
                    iSBN: response.data.iSBN,
                    name: response.data.name,
                    subtitle: response.data.subtitle,
                    vareEierStatus: response.data.vareEierStatus,
                    language: response.data.language,
                    availabilityStatus: response.data.availabilityStatus,
                }))
            })
            .catch((error) => {
                setErrorInfo(() => ({
                    buttonText: "Ok",
                    title: "Oops!",
                    message: error.message,
                    email: false,
                    showError: true,
                }))
            })
            .finally(function () {
                // always executed
            })
    }, [])

    return (
        <li>
            {errorInfo.showError && <MessageModal {...errorInfo} />}
            <div className="relative pb-4 border-b space-y-4">
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                <div className="relative flex space-x-3 justify-between">
                    <div>
                        <span className="block font-semibold text-md">{material.name}</span>
                        <span className="block text-sm">{material.subtitle}</span>
                        <span className="block text-sm pt-4 text-gray">
                            ISBN: {material.iSBN}
                            {/* <NumberFormat value={material.iSBN} displayType={"text"} format="### ## ## ### ### ### ### ###" /> */}
                        </span>
                    </div>
                    <div className="float-right text-right">
                        <span className="block text-sm italic">
                            {material.availabilityStatus}
                            {/* {material.language ? "Forventet levering 7-10 virkedager" : "Forventet salgsdato " + material.name} */}
                        </span>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default MyOrdersData
