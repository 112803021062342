import React, { useRef, useState } from "react"
import MessageModal from "../Modals/MessageModal"
import { updateInfo } from "./UserApiUtils"
import { onPaste, onVerifyEmail } from "./Utils"

/**
 * This component is for user email managment
 * Update the contact email in Dynamics365
 *
 * @version 1.0.1
 * @visibleName Update email
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const NewEmail = ({ editMode, setEditMode, userType, userInfo, setUserInfo, setAccountInfo, newUser }) => {
  const inputRef = useRef({})
  const [isEmailValid, setEmailValid] = useState(false)

  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  const onClickEmail = (newEmailInput, confirmEmailInput) => {
    const newEmail = inputRef.current[newEmailInput].value
    const confirmEmail = inputRef.current[confirmEmailInput].value

    if (newEmail === confirmEmail) {
      updateInfo(
        "email",
        inputRef.current[confirmEmailInput].value,
        setUserInfo,
        setAccountInfo,
        setEditMode,
        userInfo,
        setErrorInfo
      )
    } else
      setErrorInfo(() => ({
        buttonText: "Ok",
        title: "Oops!",
        message: "E-postfeltene er ikke like.",
        email: false,
        showError: true,
      }))
  }

  return (
    <div className="relative space-y-2">
      {errorInfo.showError && <MessageModal {...errorInfo} />}
      <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
      <h2 className="text-md font-semibold">E-post</h2>
      <div className="relative flex justify-between">
        {editMode === "email" && userType === "tibet" && (
          <div className="relative space-y-2">
            <div className="relative space-y-2">
              <input
                ref={(el) => (inputRef.current["email"] = el)}
                className="rounded text-md h-10 border-black border px-3"
                id="emailInput"
                type="email"
                defaultValue={userInfo.emailaddress1}
                placeholder="Din nye e-post"
                onChange={(e) => onVerifyEmail(e.target.value, inputRef.current["confirmEmail"].value, setEmailValid)}
                autoFocus
              />
              <br />
              <p className="pl-1 pt-2">Bekreft ny e-post adresse</p>
            </div>
            <div className="space-x-4 md:flex lg:space-x-3 lg:space-y-0 lg:flex  md:space-x-3 md:space-y-0 space-y-3">
              <div>
                <input
                  ref={(el) => (inputRef.current["confirmEmail"] = el)}
                  className="rounded text-md h-10 border-black borderpx-3"
                  id="confirmEmailInput"
                  type="email"
                  placeholder="Bekreft ny e-post"
                  onChange={(e) => onVerifyEmail(e.target.value, inputRef.current["email"].value, setEmailValid)}
                  onPaste={() => onPaste("confirmEmailInput")}
                  onFocus={() => onPaste("confirmEmailInput")}
                  autoComplete="none"
                />
              </div>
              <div className="space-x-3 text-right pr-4">
                <button
                  onClick={() => {
                    setEmailValid(false)
                    setEditMode("default")
                  }}
                  className="h-9 text-black hover:text-gray px-2 border-black w-15 border-b"
                >
                  Avbryt
                </button>
                {isEmailValid && (
                  <button
                    onClick={() => onClickEmail("email", "confirmEmail")}
                    className="h-10 bg-carmine-500 hover:bg-carmine-700 text-white font-semibold hover:text-white px-4 border border-red-500 rounded"
                  >
                    Lagre
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {editMode === "email" && userType === "feide" && (
          <div className="relative space-y-4">
            <div>
              <div className="relative pb-4">
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                <div className="relative flex space-x-3">
                  <input
                    type="text"
                    id="feideEmail"
                    className="rounded text-md h-10 text-gray-400 border-gray-400 lg:max-w-lg"
                    defaultValue={window.sessionStorage.getItem("gyldendal_minside_user_email")}
                    disabled
                  />
                </div>
                <p className="text-sm text-gray-400 italic">Obs: Denne er ikke redigerbar </p>
              </div>
              {window.sessionStorage.getItem("gyldendal_minside_user_email") !== "" ? (
                <p className="text-sm text-gray-500 italic">
                  Din e-post er registrert hos Feide. Feil e-postadresse? Ta kontakt med din lokale{" "}
                  <a href="https://www.feide.no/brukerstotte" className="underline">
                    brukerstøtte.
                  </a>{" "}
                </p>
              ) : (
                <p className="text-sm text-gray-500 italic">
                  Det ser ikke ut til at du har registrert e-post hos Feide. Ta kontakt med din lokale{" "}
                  <a href="https://www.feide.no/brukerstotte" className="underline">
                    brukerstøtte.
                  </a>{" "}
                </p>
              )}
            </div>
            <h3 className="font-medium pt-4">
              Ønsker du å motta informasjon fra Gyldendal med en annen e-postadresse?
            </h3>
            <div className="relative space-y-2">
              <input
                ref={(el) => (inputRef.current["emailFeide"] = el)}
                className="rounded text-md h-10 border-black border px-3"
                id="emailInput"
                type="text"
                placeholder="Din nye e-post"
                onChange={(e) => onVerifyEmail(e.target.value, inputRef.current["confirmEmailFeide"].value, setEmailValid)}
                autoFocus
              />
              <br />
              <p className="pl-1 pt-2">Bekreft ny e-post adresse</p>
            </div>
            <div className="relative md:flex md:space-y-0 space-y-3 space-x-5 lg:w-max w-min">
              <div>
                <input
                  ref={(el) => (inputRef.current["confirmEmailFeide"] = el)}
                  type="text"
                  id="confirmEmailFeideInput"
                  className="rounded text-md h-10 lg:max-w-lg w-min"
                  placeholder="din@epost.her"
                  onChange={(e) => onVerifyEmail(e.target.value, inputRef.current["emailFeide"].value, setEmailValid)}
                  onPaste={() => onPaste("confirmEmailFeideInput")}
                  onFocus={() => onPaste("confirmEmailFeideInput")}
                  autoComplete="none"
                />
              </div>
              <div className="space-x-3 text-right md:flex">
                <button
                  onClick={() => setEditMode("default")}
                  className="h-9 text-black hover:text-gray px-2 border-black w-15 border-b"
                >
                  Avbryt
                </button>
                {isEmailValid && (
                  <>
                    <button
                      onClick={() => onClickEmail("emailFeide", "confirmEmailFeide")}
                      className="h-10 w-15 bg-carmine-500 hover:bg-carmine-700 text-white font-semibold hover:text-white px-4 border border-red-500 rounded"
                    >
                      Lagre
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {editMode !== "email" && (
          <div className="relative flex space-x-5 justify-between">
            <p className="border-none px-0 text-md">{userInfo.emailaddress1}</p>
            <span
              className={
                newUser ? "invisible" : "text-md text-indigo-500 h-6 border-indigo-500 border-b cursor-pointer"
              }
              onClick={() => setEditMode("email")}
            >
              Endre
            </span>
            <span />
          </div>
        )}
      </div>
    </div>
  )
}

export default NewEmail
