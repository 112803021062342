import React, { useEffect, useState } from "react"
import { redirect } from "../Common/Utils"

/**
 * This component is the error message modal
 *
 * @version 1.0.1
 * @visibleName Error message modal
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const FeideMessageModal = () => {

    const OpenFeideSupport = () => {
        window.open('https://www.feide.no/brukerstotte')
        redirect()
    }

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <div
                    className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="flex justify-center ">
                        <div className="justify-center pt-10">
                            <h1 className="pt-4 italic">
                                Vi ser ikke at du er registrert med skole hos Feide.{" "}
                            </h1>
                            <div className="py-4 px-4 space-y-4">
                                <button
                                    className="h-10 w-full bg-carmine-500 hover:bg-carmine-200 text-white font-semibold hover:text-black py-2 px-6 border border-carmine-500 rounded disabled:opacity-50 disabled:cursor-default"
                                    onClick={() => OpenFeideSupport()}
                                >
                                    Ta kontakt med Feide brukerstøtte
                                </button>
                            </div>
                            <h1 className="pt-4 italic">
                                <a href="mailto:support@gyldendal.no" className="underline">Eller ta kontakt med kundesenteret.</a>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default FeideMessageModal
