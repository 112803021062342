import React, { useEffect, useState } from "react"
import MiniUserInfo from "./MiniUserInfo"
import MyAssessmentCopy from "./MyAssessmentCopy"
import MyOrders from "./MyOrders"
import MySampleAccess from "./MySampleAccess"
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid"
import { tabs, classNames } from "../CustomHooks/Tabs"
import MessageModal from "../Modals/MessageModal"
import queryString from "query-string"
import { checkExpireDate } from "../Common/WebApi-utils"
import LoadingModal from "../Modals/LoadingModal"

/**
 * This component is for showing user info and order history
 * Get order and user info from Dynamics365
 *
 * @version 1.0.2
 * @visibleName Final page - my accesses section
 * @author [Younes Hajji]
 * @author [Joachim Nordholmen]
 */

const FinalAccesses = () => {
  let redirectURl = window.sessionStorage.getItem("gyldendal_minside_redirecturl")
  if (redirectURl === null) redirectURl = "https://gyldendal.no"

  const [accessToken, setAccessToken] = useState("")
  const [fullName, setFullName] = useState("")
  const [loading, setLoading] = useState(false)

  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  let queryParam
  if (location.search !== null) {
    queryParam = queryString.parse(location.search)
    if (queryParam.token !== null && queryParam.token !== undefined) {
      window.sessionStorage.setItem("gyldendal_minside_access_token", "Bearer " + queryParam.token)
      window.sessionStorage.setItem("gyldendal_minside_access_token_expire", queryParam.token_expire)
    }
  }

  useEffect(() => {
    let queryParam
    if (location.search !== null) {
      queryParam = queryString.parse(location.search)
      if (queryParam.token !== null && queryParam.token !== undefined)
        setAccessToken("Bearer " + queryParam.token)
    }

    if (accessToken === null || accessToken === undefined || accessToken === "")
      setAccessToken(window.sessionStorage.getItem("gyldendal_minside_access_token"))

    setLoading(true)
    checkExpireDate("tilganger")
    setLoading(false)
    const index = tabs.findIndex((item) => item.href === "/app/tilganger")
    tabs[index].current = true

    setFullName(window.sessionStorage.getItem("gyldendal_minside_userfullname"))
  }, [])

  return (
    <div className="flex flex-col space-y-8 items-center justify-start">
      {loading && <LoadingModal />}
      {errorInfo.showError && <MessageModal {...errorInfo} />}
      {accessToken !== "" && accessToken !== null ? (
        <div className="max-w-3xl space-y-8">
          <div className="h-16"></div>
          <p className="text-4xl font-semibold leading-10 text-center text-gray-600">Hei, {fullName}</p>
          <div className="flex flex-col items-center justify-end max-w-3xl h-9">
            <div className="inline-flex items-center justify-center">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.current
                        ? "border-red-500 text-red-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
          <div className="mt-8 max-w-7xl mx-auto grid grid-cols-1 gap-4 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4 ">
            <div className="space-y-6 lg:col-start-1 lg:col-span-1 p-2">
              <div className="flex items-center">
                <a
                  href={redirectURl}
                  className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-blue-500 hover:text-red-700 hover:border-red-900"
                >
                  <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-blue-00" aria-hidden="true" />
                  Gå til hjemmesiden
                </a>
              </div>
              {/* <MiniUserInfo location="tilganger" /> */}
            </div>
            <div className="space-y-6 lg:col-start-2 lg:col-span-3 p-2">
              <MyOrders accessToken={accessToken} location="tilganger" />
              <MySampleAccess accessToken={accessToken} location="tilganger" />
              <MyAssessmentCopy accessToken={accessToken} location="tilganger" />
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default FinalAccesses
