import React, { useEffect, useState } from "react"
import Interests from "../Marketing/Interests/Interests"
import MarketingUserProfile from "../Marketing/MarketingUserProfile"
import Unsubscribe from "../Marketing/Unsubscribe"
import queryString from "query-string"
import { tabs, classNames } from "../CustomHooks/Tabs"
import MessageModal from "../Modals/MessageModal"
import MarketingSchool from "../Marketing/MarketingSchool"
import { HorizontalDivider } from "../Graphics/HorizontalDivider"
import LoadingModal from "../Modals/LoadingModal"
import { checkExpireDate, fetchApi } from "../Common/WebApi-utils"

/**
 * This component is for showing/editing a list of preferences, user preferences and user consent and userinfo
 * The preferences are based on the marketing lists in dynamics.
 * changes to the list structure has to be done in Dynamics365
 * Possibility to unsuvscribe
 *
 * @version 1.0.2
 * @visibleName Final page - newsletter section
 * @author [Younes Hajji]
 * @author [Joachim Nordholmen]
 */

const ProfileSettings = () => {
  let redirectURl = window.sessionStorage.getItem("gyldendal_minside_redirecturl")
  if (redirectURl === null) redirectURl = "https://gyldendal.no"

  const [user, setUser] = useState([])
  const [fullName, setFullName] = useState("")
  const [accessToken, setAccessToken] = useState("")
  const [consent, setConsent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)

  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  let queryParam
  if (location.search !== null) {
    queryParam = queryString.parse(location.search)
    if (queryParam.token !== null && queryParam.token !== undefined) {
      window.sessionStorage.setItem("gyldendal_minside_access_token", "Bearer " + queryParam.token)
      window.sessionStorage.setItem("gyldendal_minside_access_token_expire", queryParam.token_expire)
    }
  }

  const loadData = () => {
    fetchApi(
      process.env.GATSBY_API_URL_APIM +
      "/GyldendalContacts?accountId=" +
      window.sessionStorage.getItem("gyldendal_minside_accountid"),
      window.sessionStorage.getItem("gyldendal_minside_access_token"),
      "instillinger"
    )
      .then((response) => {
        setUser(response.data)

        if (response.data.consent === 587030004)
          //Markedsføring
          setConsent(true)

        if (response.data.consent === 292460000)
          //Ingen
          setConsent(false)

        window.sessionStorage.setItem("gyldendal_minside_userfullname", response.data.fullname)
        setFullName(response.data.fullname)
        if (response.data.account !== undefined) {
          window.sessionStorage.setItem("gyldendal_minside_accountid", response.data.account.id)
          window.sessionStorage.setItem("gyldendal_minside_crmorgid", response.data.account.organizationNumber)
          window.sessionStorage.setItem("gyldendal_minside_accountname", response.data.account.name)
        }
      })
      .catch((_error) => { })
      .finally(function () {
        setLoading(false)
      })
  }

  useEffect(() => {
    let queryParam
    if (location.search !== null) {
      queryParam = queryString.parse(location.search)
      if (queryParam.token !== null && queryParam.token !== undefined)
        setAccessToken("Bearer " + queryParam.token)
    }

    if (accessToken === null || accessToken === undefined || accessToken === "")
      setAccessToken(window.sessionStorage.getItem("gyldendal_minside_access_token"))

    setLoading(true)
    checkExpireDate("instillinger")
    setLoading(false)
    loadData()
  }, [])

  const unsebscribeChange = (changed) => {
    if (changed) {
      setReload(true)
      setConsent(false)
    }
  }

  useEffect(() => {
    if (reload) setReload(false)
  }, [reload])

  useEffect(() => {
    const index = tabs.findIndex((item) => item.href === "/app/instillinger")
    tabs[index].current = true
  }, [])

  return (
    <div className="flex flex-col items-center">
      {loading && <LoadingModal />}
      {accessToken !== "" && accessToken !== null ? (
        <div className="max-w-3xl space-y-8">
          {errorInfo.showError && <MessageModal {...errorInfo} />}
          <div className="h-16"></div>
          <p className="text-4xl font-semibold leading-10 text-center text-gray-600">Hei, {fullName}</p>
          <div className="flex flex-col items-center justify-end max-w-3xl h-9">
            <div className="inline-flex items-center justify-center">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.current
                        ? "border-red-500 text-red-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
          <MarketingUserProfile setUser={setUser} user={user} accessToken={accessToken} location="instillinger" />
          <MarketingSchool user={user} accessToken={accessToken} location="instillinger" />
          <HorizontalDivider />
          <span className="h-24"></span>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default ProfileSettings
