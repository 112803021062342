import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"
import SEO from "../SEO/SEO"
import CancelModal from "../Modals/CancelModal"
import { fetchApi, postApi } from "../Common/WebApi-utils"
import { webApi } from "../Security/ApiEndpoints"
import LoadingModal from "../Modals/LoadingModal"
import NumberFormat from "react-number-format"
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid"
import { GetLanguageName } from "../Common/Utils"
import MessageModal from "../Modals/MessageModal"

/**
 * This component is for listing the materials from Dynamics365 to choose from to order
 *
 * @version 1.0.1
 * @visibleName Assessment Copy list
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
let redirectURl = "https://gyldendal.no"

const FormAssessmentCopy = ({ account }) => {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [materialGroup, setMaterialGroup] = useState([])
  const [showList, setShowList] = useState(true)
  const [isSend, setIsSend] = useState(true)
  const [businessCategory, setBusinessCategory] = useState("")

  const [errorInfo, setErrorInfo] = useState({
    title: "Oops!",
    message: "Det er registrert en feil i materiellet du forsøker å bestille.",
    buttonText: "Ok",
    email: false,
    showError: false,
  })

  useEffect(() => {
    var url = window.sessionStorage.getItem("gyldendal_minside_redirecturl")
    if (url !== null) redirectURl = url

    setLoading(true)
    const materialGroupId = window.sessionStorage.getItem("gyldendal_minside_groupid")
    const accountId = window.sessionStorage.getItem("gyldendal_minside_accountid")

    fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalMaterialGroup/" + materialGroupId + "/" + accountId)
      .then((response) => {
        setShowList(true)
        setMaterialGroup(response.data)
        response.data.forEach((element) => {
          if (element.businessCategory !== null) setBusinessCategory(element.businessCategory)
        })

        if (response.data.length === 0) setShowList(false)
      })
      .catch((error) => {
        setShowList(false)

        if (error.message.includes("404"))
          setErrorInfo(() => ({
            buttonText: "Ok",
            title: "Oops!",
            message: error.message + ": Enten materialgruppen med id " + materialGroupId + " finnes ikke, eller du er ikke koblet en skole i Dynamics.",
            email: false,
            showError: true,
          }))
        else
          setErrorInfo(() => ({
            buttonText: "Ok",
            title: "Oops!",
            message: error.message,
            email: false,
            showError: true,
          }))
      })
      .finally(function () {
        setLoading(false)
      })
  }, [account])

  const onChange = () => {
    const arr = document.getElementsByName("available_book")
    let countChecked = 0

    arr.forEach((element) => {
      element.checked === true ? (countChecked += 1) : null
    })

    countChecked > 0 ? setIsSend(false) : setIsSend(true)
  }

  const createOrderArray = () => {
    let orderArray = {
      accountId: window.sessionStorage.getItem("gyldendal_minside_accountid"),
      deliveryTo: window.sessionStorage.getItem("gyldendal_minside_accountname"),
      contactName: window.sessionStorage.getItem("gyldendal_minside_userfullname"),
      businessCategory: businessCategory?.toString(),
      ordreLine: [],
    }
    var availableBooks = Array.prototype.slice.call(document.getElementsByName("available_book"))

    availableBooks.map((book) => {
      if (book.checked === true) {
        orderArray.ordreLine.push({
          MaterialId: book.id,
          addAsOrder: true,
        })
      }
    })

    setLoading(true)
    postApi(process.env.GATSBY_API_URL_APIM + "/GyldendalOrders/makeOrder", orderArray)
      .then((response) => {
        window.sessionStorage.setItem("gyldendal_minside_orderid", response.data)
        navigate("/app/tilganger")
      })
      .catch((error) => {
        setErrorInfo(() => ({
          buttonText: "Ok",
          title: "Oops!",
          message: "Kunne ikke opprette ordre: " + error.message,
          email: false,
          showError: true,
        }))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className="flex pt-10 bg-white">
        <SEO title="Bestilling" description="Gyldendal sin hjem side." />
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 m-auto sm:p-2 bg-white">
          {loading ? <LoadingModal /> : null}
          <div className="flex items-center">
            <a
              href={redirectURl}
              className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-blue-500 hover:text-red-700 hover:border-red-900"
            >
              <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-blue-00" aria-hidden="true" />
              Gå til hjemmesiden
            </a>
          </div>
          {showList ? (
            <div className="bg-white sm:p-2">
              <div>
                <h2 className="text-3xl font-bold pb-3">Forespørsel om vurderingseksemplar</h2>
                <h5 className="text-md">
                  Vi tilbyr et utvalg av våre nyeste titler som gratis vurderingseksemplar til skolen.
                </h5>
                <h5 className="text-md">Hver skole kan kun motta et begrenset antall vurderingseksemplarer.</h5>
              </div>
              <div className="pt-10">
                <h3 className="font-semibold">Velg vurderingseksemplar</h3>
                <div className="max-w-full">
                  {materialGroup.map((book) => (
                    <div key={book.id} className="relative flex justify-between border-b p-3">
                      <div className="flex space-x-2">
                        <div className="inline-flex items-center mt-3 box-shadow-none">
                          {book.isOrder === false ? (
                            <input
                              id={book.id}
                              name="unavailable_book"
                              type="checkbox"
                              className="form-checkbox h-7 w-7 text-gray-500 rounded absolute left-1 top-4 disabled:opacity-50"
                              checked
                              disabled
                            />
                          ) : (
                            <input
                              id={book.id}
                              name="available_book"
                              type="checkbox"
                              className="form-checkbox h-7 w-7 text-carmine-500 rounded absolute left-1 top-4 focus:ring-transparent"
                              onChange={onChange}
                            />
                          )}
                        </div>
                        <div>
                          <label htmlFor="settings-option-0" className="ml-3 flex flex-col cursor-pointer pl-5">
                            <span className="block font-semibold text-md ">{book.name}</span>
                            <span className="block text-sm">{book.subtitle}</span>
                            <span className="block text-sm text-gray pt-4 text-gray-600">ISBN: {book.iSBN}</span>
                            <span className="block text-sm text-gray pt-2 text-gray-800">{GetLanguageName(book.language)}</span>
                            {book.isOrder === false ? (
                              <span className="block font-semibold text-md ">
                                *Skolen din har allerede mottatt maks antall vurderingseksemplarer.{" "}
                                <a
                                  href="https://www.gyldendal.no/artikler/spoersmal-og-svar-om-vurderingseksemplar/"
                                  className="underline"
                                >
                                  {" "}
                                  Les mer
                                </a>
                              </span>
                            ) : null}
                          </label>
                        </div>
                      </div>
                      <div className="justify-end text-right">
                        <p className="text-sm pt-4 text-right break-words">{book.availabilityStatus}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {modal ? <CancelModal proceed={() => setModal(false)} /> : null}

              <div className="flex justify-start justify-end">
                <button
                  id="btnCancel"
                  onClick={() => setModal(true)}
                  className="h-10 m-2 underline bg-transparent text-black font-semibold hover:text-gray py-2 px-6 borde-none"
                >
                  Avbryt
                </button>
                <button
                  id="btnSend"
                  disabled={isSend}
                  onClick={createOrderArray}
                  className="h-10 m-2 bg-carmine-500 hover:bg-carmine-800 text-white font-semibold hover:text-white py-2 px-6 border border-red-500 rounded disabled:opacity-50"
                >
                  Send
                </button>
              </div>
            </div>
          ) : (
            <MessageModal {...errorInfo} />
          )}
        </div>
      </div>
    </>
  )
}

export default FormAssessmentCopy
