import React, { useEffect, useState } from "react"
import { fetchApi } from "../Common/WebApi-utils"
import Moment from "react-moment"
import "moment-timezone"
import MessageModal from "../Modals/MessageModal"

/**
 * This component is for showing my ordered Tibet access data from Dynamics365
 * @remark This data is listed in the component "MySampleAccess"
 *
 * @version 1.0.1
 * @visibleName My Assessment Copy Data
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const MySampleAccessData = ({ data }) => {
    const [material, setMaterial] = useState({
        id: "",
        ISBN: "",
        name: "",
        subtitle: "",
        language: "",
    })

    const [errorInfo, setErrorInfo] = useState({
        title: "",
        message: "",
        buttonText: "",
        email: false,
        showError: false,
    })

    useEffect(() => {
        const materialId = data.material
        fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalMaterials/" + materialId)
            .then((response) => {
                setMaterial((prevState) => ({
                    ...prevState,
                    id: response.data.id,
                    ISBN: response.data.iSBN,
                    name: response.data.name,
                    subtitle: response.data.subtitle,
                    language: response.data.language,
                }))
            })
            .catch((error) => {
                setErrorInfo(() => ({
                    buttonText: "Ok",
                    title: "Oops!",
                    message: error.message,
                    email: false,
                    showError: true,
                }))
            })
            .finally(() => {
                //allways executed
            })
    }, [])
    return (
        <li>
            {errorInfo.showError && <MessageModal {...errorInfo} />}
            <div className="relative pb-4 border-b space-y-4">
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                <div className="relative flex space-x-3 justify-between">
                    <div className="min-w-0 flex-1 flex space-x-4 justify-between">
                        <div>
                            <span className="block font-semibold text-md">{material.name}</span>
                            <span className="block text-sm">{material.subtitle}</span>
                            <span className="block text-sm pt-2">
                                ISBN: {material.ISBN}
                                {/* <NumberFormat value={material.ISBN} displayType={"text"} format="### ## ## ### ### ### ### ###" /> */}
                            </span>
                        </div>
                        <div className="text-right float-right">
                            {data.accessTo < Date() ? (
                                <>
                                    <span className="text-right text-sm text-indigo-500 pb-4">
                                        {" "}
                                        Utløper <Moment format="DD.MM.YYYY">{data.accessTo}</Moment>{" "}
                                    </span>
                                    <div className="flex pt-2">
                                        <span className="text-right text-sm text-gray-800 ">Gå til produktet </span>
                                        <div>
                                            <span className="h-8 w-8 rounded-full flex items-center justify-end transform -rotate-45">
                                                <a href={data.accessUrl}>
                                                    <svg
                                                        className="h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="tomato"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893L15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.7071 9.70711C11.3166 10.0976 10.6834 10.0976 10.2929 9.70711C9.90238 9.31658 9.90238 8.68342 10.2929 8.29289L12.5858 6H1C0.447716 6 -2.41411e-08 5.55228 0 5C2.41411e-08 4.44772 0.447716 4 1 4H12.5858L10.2929 1.70711C9.90238 1.31658 9.90238 0.683418 10.2929 0.292893Z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </span>
                                        </div>
                                    </div>{" "}
                                </>
                            ) : (
                                <>
                                    <span className="text-right text-sm text-carmine-600 pb-4">Utløpt</span>
                                    <div className="flex pt-2">
                                        <span className="text-right text-xs text-gray-500 italic">
                                            Ta kontakt med administrator ved din skole for bestilling av lisens{" "}
                                        </span>
                                    </div>{" "}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default MySampleAccessData
