import React from "react"
import Helmet from "react-helmet"

/**
 * This component is the SEO
 * @remark not relevant for the portal at this point.
 *
 * @version 1.0.1
 * @visibleName SEO
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const SEO = ({ title, description }) => {
  return (
    <Helmet htmlAttributes={{ lang: "no" }} title={`${title}`}>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default SEO
