import React, { useEffect, useMemo, useState } from "react"
import { HandleMiniUserAccountMemo, HandleMarketingUserEffect } from "../Common/UserApiUtils"
import { patchApiNewsLetter } from "../Common/WebApi-utils"
import SearchableDropDown from "../DropDowns/SearchableDropdown"
import LoadingModal from "../Modals/LoadingModal"

/**
 * This component is for showing and editing the school data from Dynamics365
 * @param param0
 * @returns
 */
const MarketingSchool = ({ user, accessToken, location }) => {
  const [editMode, setEditMode] = useState("default")
  const [account, setAccount] = useState({})
  const [loading, setLoading] = useState(false)

  const [accountInfo, setAccountInfo] = useState({
    accountid: "",
    accountname: "",
    address: "",
    zipCode: "",
    city: "",
  })

  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  useEffect(() => {
    HandleMarketingUserEffect(
      setAccount,
      setErrorInfo,
      window.sessionStorage.getItem("gyldendal_minside_access_token"),
      location
    )
  }, [])

  useMemo(() => {
    HandleMiniUserAccountMemo(setAccountInfo, account)
  }, [account])

  const onEdit = () => {
    setEditMode("edit")
  }
  const onCancel = () => {
    setEditMode("default")
  }

  const onSend = () => {
    setLoading(true)
    window.sessionStorage.setItem("gyldendal_minside_accountid", accountInfo.accountid)

    const SecurityIdentityData = {
      AccountId: accountInfo.accountid,
      isNewUser: window.sessionStorage.getItem("gyldendal_minside_new_contact"),
    }

    patchApiNewsLetter(
      process.env.GATSBY_API_URL_APIM + "/GyldendalSecurityIdentities",
      SecurityIdentityData,
      accessToken,
      location
    )
      .then((response) => {
        window.sessionStorage.setItem("gyldendal_minside_new_contact", "false")
      })
      .catch((error) => {
        setErrorInfo(() => ({
          buttonText: "Ok",
          title: "Oops!",
          message: "Kunne oppdatere virksomheten på identiteten din: " + error.message,
          email: false,
          showError: true,
        }))
      })
      .finally(function () {
        setLoading(false)
      })

    setEditMode("default")
  }

  return (
    <div className="w-full ">
      {loading && <LoadingModal />}
      {editMode === "edit" ? (
        <div className="flex flex-col space-y-2 items-start justify-start w-full ">
          <div className="inline-flex space-x-6 items-start justify-between p-6 bg-gray-50 border rounded border-gray-300 w-full">
            <div className="relative px-2 space-y-2 w-full">
              <SearchableDropDown setAccountInfo={setAccountInfo} />
              <div className="flex flex-col space-y-5 items-start justify-start w-full">
                <div className="bg-gray-200 w-full" />
                <div className="inline-flex space-x-3 items-center justify-start w-full">
                  <button
                    onClick={() => onSend()}
                    className="flex items-center justify-center px-4 py-2 bg-red-600 shadow rounded-md w-full hover:bg-pink-700"
                  >
                    <div className="text-sm font-semibold leading-tight text-white">Lagre endringer</div>
                  </button>
                  <button
                    onClick={() => onCancel()}
                    className="flex items-center justify-center px-4 py-2 bg-white shadow border rounded-md border-gray-300 w-full hover:bg-gray-50"
                  >
                    <div className="text-sm font-semibold leading-tight text-gray-700">Angre</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="inline-flex space-x-6 items-start justify-between p-6 bg-gray-50 border rounded border-gray-300 w-full">
          <div className="inline-flex flex-col space-y-2 items-start justify-start">
            <p className="text-lg font-semibold leading-normal text-gray-600">Skole/firma</p>
            <p className="text-sm leading-tight text-gray-600">
              {accountInfo.accountname}, {accountInfo.address}, {accountInfo.zipCode} {accountInfo.city}
            </p>
          </div>
          <button
            onClick={() => onEdit()}
            className="flex items-center justify-center px-4 py-2 bg-white shadow border rounded-md border-gray-300 hover:bg-gray-50"
          >
            <div className="text-sm font-semibold leading-tight text-gray-700">Endre</div>
          </button>
        </div>
      )}
    </div>
  )
}

export default MarketingSchool
