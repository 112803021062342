import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { updateInfo } from "../Common/Utils"
import { postApi } from "../Common/WebApi-utils"
import { webApi } from "../Security/ApiEndpoints"
import SEO from "../SEO/SEO"
import SampleAccess from "./SampleAccess"
import LoadingModal from "../Modals/LoadingModal"
import CancelModal from "../Modals/CancelModal"
import moment from "moment"
import MessageModal from "../Modals/MessageModal"
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid"

/**
 * This component is for ordering Tibet Access from Dynamics365
 *
 * @version 1.0.1
 * @visibleName Tibet Access order
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
let redirectURl = "https://gyldendal.no"

const FormSampleAccess = () => {
  const [loading, setLoading] = useState(true)
  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  useEffect(() => {
    var url = window.sessionStorage.getItem("gyldendal_minside_redirecturl")
    if (url !== null) redirectURl = url

    const expireDate = window.sessionStorage.getItem("gyldendal_minside_expiredate")

    const tibetApiData = {
      ISBN: window.sessionStorage.getItem("gyldendal_minside_productid"),
      accessUrl: window.sessionStorage.getItem("gyldendal_minside_producturl"),
      accessFrom: moment().format(),
      accessTo: moment(expireDate).format(),
      accountId: window.sessionStorage.getItem("gyldendal_minside_accountid"),
      accessType: 157580000,
    }

    window.sessionStorage.setItem("gyldendal_minside_tibetaccess", "true")
    postApi(process.env.GATSBY_API_URL_APIM + "/GyldendalTibetAccesses", tibetApiData)
      .then(() => {
        window.sessionStorage.setItem("gyldendal_minside_productid", "")
        window.sessionStorage.setItem("gyldendal_minside_producturl", "")
        window.sessionStorage.setItem("gyldendal_minside_accountid", "")
        navigate(redirectURl)
      })
      .catch((error) => {
        setErrorInfo(() => ({
          title: "Oops!",
          message: "Det er registrert en feil i materiellet du forsøker å bestille.",
          buttonText: "Vi kan hjelpe deg, ta kontakt!",
          email: true,
          showError: true,
        }))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div>
      {loading && <LoadingModal />}
      {errorInfo.showError ? <MessageModal {...errorInfo} /> : <div></div>}
    </div>
  )
}

export default FormSampleAccess
