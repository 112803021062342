import { convertFirstCharacterToUppercase, getOrgNumber } from "./Utils"
import { fetchApi, fetchNSKApi, patchApi, postApi } from "./WebApi-utils"

export const closeModal = async (setModalInfo) => {
    setModalInfo(() => ({
        showMessage: false,
    }))
}

export const updateSchool = (accountInfo, setEditMode, setErrorInfo) => {
    // const data = { parentAccountId: accountInfo.accountid }
    window.sessionStorage.setItem("gyldendal_minside_accountid", accountInfo.accountid)

    const SecurityIdentityData = {
        AccountId: accountInfo.accountid,
        isNewUser: window.sessionStorage.getItem("gyldendal_minside_new_contact"),
    }

    patchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalSecurityIdentities", SecurityIdentityData)
        .then((response) => {
            window.sessionStorage.setItem("gyldendal_minside_new_contact", "false")
        })
        .catch((error) => {
            setErrorInfo(() => ({
                buttonText: "Ok",
                title: "Oops!",
                message: "Kunne oppdatere virksomheten på identiteten din: " + error.message,
                email: false,
                showError: true,
            }))
        })
        .finally(function () {
            // always
        })

    setEditMode("default")
}

//Handle Feide school update
// export const HandleFeideSchool = (queryParam, setFeideSchool, setNewAccountId, setErrorInfo) => {
//     const Orgnr = getOrgNumber(queryParam.schoolNr)

//     fetchNSKApi(Orgnr)
//         .then((response) => {
//             setFeideSchool(() => ({
//                 name: response.data.Navn,
//                 organizationNumber: Orgnr,
//                 address: response.data.Beliggenhetsadresse.Adresse,
//                 zipCode: response.data.Beliggenhetsadresse.Postnr,
//                 city: response.data.Beliggenhetsadresse.Poststed,
//                 land: response.data.Beliggenhetsadresse.Land,
//             }))

//             const crmOrgId = window.sessionStorage.getItem("gyldendal_minside_crmorgid")
//             if (crmOrgId !== Orgnr) {
//                 const SchoolData = {
//                     name: response.data.Navn,
//                     organizationNumber: response.data.Orgnr,
//                     address: response.data.Beliggenhetsadresse.Adresse,
//                     zipCode: response.data.Beliggenhetsadresse.Postnr,
//                     country: response.data.Beliggenhetsadresse.Land,
//                     // city: response.data.Beliggenhetsadresse.Poststed,
//                     addressPost: response.data.Postadresse.Adresse,
//                     zipCodePost: response.data.Postadresse.Postnr,
//                     countryPost: response.data.Postadresse.Land,
//                     // cityPost: response.data.Postadresse.Poststed,
//                     isPrimarySchool: response.data.ErGrunnskole,
//                     isHighSchool: response.data.ErVideregaaendeSkole,
//                     studentCount: response.data.Elevtall,
//                     nsrNumber: queryParam.schoolNr,
//                 }

//                 postApi(process.env.GATSBY_API_URL_APIM + "/GyldendalAccounts/getByOrgId", SchoolData)
//                     .then((response) => {
//                         setNewAccountId(response.data.id)
//                         // TODO: Uncoment after testing
//                         window.sessionStorage.setItem("gyldendal_minside_accountid", response.data.id)
//                         window.sessionStorage.setItem("gyldendal_minside_accountname", response.data.name)
//                     })
//                     .catch((error) => {
//                         if (error.message.includes("404"))
//                             setErrorInfo(() => ({
//                                 title: "Oops!",
//                                 message: error.message + ": Skole med org. nr. finnes ikke i CRM: " + response.data.Orgnr,
//                                 buttonText: "Vi kan hjelpe deg, ta kontakt!",
//                                 email: true,
//                                 showError: false,
//                             }))
//                         else
//                             setErrorInfo(() => ({
//                                 title: "Oops!",
//                                 message: error.message,
//                                 buttonText: "Vi kan hjelpe deg, ta kontakt!",
//                                 email: true,
//                                 showError: false,
//                             }))
//                     })
//                     .finally(function () {
//                         // always
//                     })
//             }
//         })
//         .catch((error) => {
//             setErrorInfo(() => ({
//                 title: "Vi ser ikke at du er registrert med skole hos Feide.",
//                 message: "Kontakt brukerstøtte Feide: https://www.feide.no/brukerstotte",
//                 buttonText: "Vi kan hjelpe deg, ta kontakt!",
//                 email: true,
//                 showError: true,
//             }))
//         })
//         .finally(() => {
//             //always execute
//         })
// }

//User info verfiy user
export const HandlUserInfoVerify = (userInfo, setErrorInfo, newAccountId, userType) => {
    const today = new Date()
    let data = { profileVerifiedOn: today }

    const emailConfirmedAt = window.sessionStorage.getItem("gyldendal_minside_emailconfirmedat")

    if (emailConfirmedAt !== "null") data = { profileVerifiedOn: today, emailVerifiedOn: userInfo.emailVerifiedOn }

    patchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalContacts", data)
        .then((response) => { })
        .catch((error) => {
            setErrorInfo(() => ({
                buttonText: "Ok",
                title: "Oops!",
                message: error.message,
                email: false,
                showError: true,
            }))
        })

    // TODO: Uncoment after testing
    if (newAccountId) {
        const SecurityIdentityData = {
            AccountId: newAccountId,
            isNewUser: window.sessionStorage.getItem("gyldendal_minside_new_contact"),
        }

        patchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalSecurityIdentities", SecurityIdentityData)
            .then((response) => {
                window.sessionStorage.setItem("gyldendal_minside_new_contact", "false")
            })
            .catch((error) => {
                setErrorInfo(() => ({
                    buttonText: "Ok",
                    title: "Oops!",
                    message: "Kunne ikke oppdatere virksomheten på identiteten din: " + error.message,
                    email: false,
                    showError: true,
                }))
            })
            .finally(function () {
                // always
            })
    }
}

//Userinfo set memo
export const HandleUserInfoMemo = (setUserInfo, user, setPhoneValue) => {
    setUserInfo((prevState) => ({
        ...prevState,
        emailaddress1: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        fullname: user.fullname,
        mobilephone: user.mobilephone,
        emailVerifiedOn: user.emailVerifiedOn,
        mobileVerifiedDate: user.mobileVerifiedDate,
        profileverifiedon: user.profileVerifiedOn,
    }))

    setPhoneValue(user.mobilephone)

    var emailConfirmedAt = window.sessionStorage.getItem("gyldendal_minside_emailconfirmedat")

    if (emailConfirmedAt !== "null" && user.emailVerifiedOn === undefined) {
        setUserInfo((prevState) => ({
            ...prevState,
            emailVerifiedOn: emailConfirmedAt,
        }))
    }

    if (user.emailVerifiedOn === undefined && emailConfirmedAt === "null") {
        setUserInfo((prevState) => ({
            ...prevState,
            emailaddress1: null,
        }))
    }

    if (user.mobileVerifiedDate === undefined) {
        setUserInfo((prevState) => ({
            ...prevState,
            mobilephone: null,
        }))

        setPhoneValue(null)
    }
}


//Update User info in Dynamics365
export const updateInfo = (
    patchMode,
    inputRef,
    setUserInfo,
    setAccountInfo,
    setEditMode,
    userInfo,
    setErrorInfo?
) => {
    let data = {}
    const today = new Date()
    const prevInfo = userInfo

    inputRef === "" ? (patchMode = "null") : null

    switch (patchMode) {
        case "name":
            const fnameValueRaw = inputRef.split(":")[0]
            const lnameValueRaw = inputRef.split(":")[1]

            const fnameValueArray = fnameValueRaw.split(" ")
            const fnameValueWithUpperCase = fnameValueArray.map((partialName) => {
                return convertFirstCharacterToUppercase(partialName)
            })
            const fnameValue = fnameValueWithUpperCase.join(" ")

            const lnameValueArray = lnameValueRaw.split(" ")
            const lnameValueWithUpperCase = lnameValueArray.map((partialName) => {
                return convertFirstCharacterToUppercase(partialName)
            })
            const lnameValue = lnameValueWithUpperCase.join(" ")

            data = { firstname: fnameValue, lastname: lnameValue }
            setUserInfo((prevState) => ({
                ...prevState,
                firstname: fnameValue,
                lastname: lnameValue,
                fullname: fnameValue + " " + lnameValue,
            }))
            window.sessionStorage.setItem("gyldendal_minside_userfullname", fnameValue + " " + lnameValue)
            break
        case "phone":
            const phoneValue = inputRef.replace("+", "00")

            data = { mobilephone: phoneValue, mobileVerifiedDate: today }
            setUserInfo((prevState) => ({
                ...prevState,
                mobilephone: phoneValue,
                mobileVerifiedDate: today,
            }))
            break
        case "email":
            const emailValue = inputRef

            data = { email: emailValue, emailVerifiedOn: today }
            setUserInfo((prevState) => ({
                ...prevState,
                emailaddress1: emailValue,
                emailVerifiedOn: today,
            }))
            break
        case "school":
            const schoolId = window.sessionStorage.getItem("gyldendal_minside_accountid")
            const schoolName = window.sessionStorage.getItem("gyldendal_minside_schoolname")
            data = { parentAccountId: schoolId }
            setAccountInfo((prevState) => ({
                ...prevState,
                accountname: schoolName,
            }))
            break
        default:
            break
    }

    patchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalContacts", data)
        .then((response) => { })
        .catch((error) => {
            setErrorInfo(() => ({
                buttonText: "Ok",
                title: "Oops!",
                message: error.message,
                email: false,
                showError: true,
            }))
            setUserInfo(() => prevInfo)
            window.sessionStorage.setItem("gyldendal_minside_userfullname", userInfo.fullname)
        })

    setEditMode("default")
}

export const HandleUserAccountMemo = (setAccountInfo, account) => {
    setAccountInfo((prevState) => ({
        ...prevState,
        accountid: account.accountid,
        accountname: account.name,
        address: account.address,
        zipCode: account.zipCodeValue,
        city: account.postAdressePoststedValue,
        orgnr: account.organizationNumber,
    }))
}

//-------------------MINI USER ----------------

export const HandleMiniUserInfoUpdate = (userInfo, data, isEmailValid, setUserInfo, setErrorInfo, location) => {
    if (!isEmailValid) {
        setErrorInfo(() => ({
            buttonText: "Ok",
            title: "Oops!",
            message: "Mailen er ikke bekreftet, prøv igjen",
            email: false,
            showError: true,
        }))
        return
    }

    patchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalContacts", data, location)
        .then((response) => {
            setErrorInfo(() => ({
                buttonText: "Ok",
                title: "Oops!",
                message: "Kontaktinformasjon oppdatert",
                email: false,
                showError: true,
            }))
        })
        .catch((error) => {
            setErrorInfo(() => ({
                buttonText: "Ok",
                title: "Oops!",
                message: "Kunne ikke oppdatere kontakten: " + error.message,
                email: false,
                showError: true,
            }))
        })
}

export const HandleMiniUserEffect = (setUser, setAccount, setFeide, setErrorInfo, location) => {
    window.sessionStorage.getItem("gyldendal_minside_usertype") === "feide" ? setFeide(true) : setFeide(false)
    fetchApi(
        process.env.GATSBY_API_URL_APIM + "/GyldendalContacts?accountId=" + window.sessionStorage.getItem("gyldendal_minside_accountid"), location
    )
        .then((response) => {
            setUser(response.data)
            setAccount(response.data.account)
        })
        .catch((error) => {
            setErrorInfo(() => ({
                buttonText: "Ok",
                title: "Oops!",
                message: error.message,
                email: false,
                showError: true,
            }))
        })
}

export const HandleMarketingUserEffect = (setAccount, setErrorInfo, accessToken, location) => {
    fetchApi(
        process.env.GATSBY_API_URL_APIM +
        "/GyldendalContacts?accountId=" +
        window.sessionStorage.getItem("gyldendal_minside_accountid"),
        accessToken,
        location
    )
        .then((response) => {
            setAccount(response.data.account)
        })
        .catch((error) => {
            setErrorInfo(() => ({
                buttonText: "Ok",
                title: "Oops!",
                message: error.message,
                email: false,
                showError: true,
            }))
        })
}

export const HandleMiniUserAccountMemo = (setAccountInfo, account) => {
    if (account !== undefined && account !== null && account !== "")
        setAccountInfo((prevState) => ({
            ...prevState,
            accountid: account.id,
            accountname: account.name,
            address: account.address,
            zipCode: account.zipCodeValue,
            city: account.postAdressePoststedValue,
        }))
}

export const HandleMiniUserInfoMemo = (setUserInfo, setPhoneValue, user) => {
    setUserInfo((prevState) => ({
        ...prevState,
        emailaddress1: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        fullname: user.fullname,
        mobilephone: user.mobilephone,
    }))
    setPhoneValue(user.mobilephone)
}
