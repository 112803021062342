import { useState } from "react"
import validator from "validator"
import { navigate } from "gatsby"

/**
 * This component is for various helper methods
 *
 * @version 1.0.1
 * @visibleName Utils
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
export const isNullOrUndefined = (test: any) => {
  return !test || test == null || test === undefined || test === "null" || test === "undefined"
}

export const getOrgNumber = (nsrnr) => {
  if (!isNullOrUndefined(nsrnr)) {
    let org = nsrnr.replace("U", "")
    org = nsrnr.replace("NO", "")

    return org
  }
}

export const onVerifyName = (val, setNameValid) => {
  var regex = new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g)
  if (regex.test(val)) {
    setNameValid(true)
  } else {
    setNameValid(false)
  }
}

export const redirect = () => {
  let url = window.sessionStorage.getItem("gyldendal_minside_redirecturl")
  const navigateUrl = url ?? ""

  if (isNullOrUndefined(url)) navigate("https://www.gyldendal.no/")

  // add http:// if its not in the url. requierd for new URL()
  if (!url?.includes("http")) url = "http://" + url

  //gets hostname (www.domain.com) and removes www.
  const domain = new URL(url).hostname.replace("www.", "")

  if (domain === process.env.GATSBY_REDIRECT_URL) {
    navigate(navigateUrl)
  }
  navigate(url)
}

export const onPaste = (elementById) => {
  const pasteBox = document.getElementById(elementById)
  pasteBox.onpaste = (e) => {
    e.preventDefault()
    return false
  }
}

export const onVerifyEmail = (val, comparison, setEmailValid) => {
  var regex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
  if (regex.test(val) && val === comparison) {
    setEmailValid(true)
  } else {
    setEmailValid(false)
  }
}

export const onValidateEmail = (val, setEmailValid) => {
  var regex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
  if (regex.test(val)) {
    setEmailValid(true)
  } else {
    setEmailValid(false)
  }
}

export const cancelMethod = (setAccountInfo, account, setEditMode) => {
  setAccountInfo((prevState) => ({
    ...prevState,
    accountid: account.id,
    accountname: account.name,
    address: account.address,
    zipCode: account.zipCodeValue,
    city: account.postAdressePoststedValue,
  }))
  setEditMode("default")
}

export const convertFirstCharacterToUppercase = (stringToConvert) => {
  var firstCharacter = stringToConvert.substring(0, 1)
  var restString = stringToConvert.substring(1)

  return firstCharacter.toUpperCase() + restString
}

export const validatePhone = (value) => {
  const newValue = "+" + value
  const unformattedMobile = newValue.replace(/\s+/g, "")

  if (!validator.isMobilePhone(unformattedMobile, "any")) {
    return false
  }
  return true
}

export const validatePhoneMiniUser = (value, setPhoneValid) => {
  const newValue = "+" + value
  const unformattedMobile = newValue.replace(/\s+/g, "")

  if (!validator.isMobilePhone(unformattedMobile, "any")) {
    setPhoneValid(false)
    return false
  }
  setPhoneValid(true)
  return true
}

export const GetLanguageName = (langCode) => {
  switch (langCode) {
    case 157580000:
      return "Bokmål"
    case 157580032:
      return "Serbokroatisk"
    case 157580001:
      return "Bokmål"
    case 157580033:
      return "Serbisk"
    case 157580002:
      return "Bokmål og nynorsk"
    case 157580034:
      return "Tsjekkisk"
    case 157580003:
      return "Bokmål Og Samisk"
    case 157580035:
      return "Ungarsk"
    case 157580004:
      return "Norsk, dialekt"
    case 157580036:
      return "Urdu"
    case 157580005:
      return "Nynorsk"
    case 157580037:
      return "Koreansk"
    case 157580006:
      return "Albansk"
    case 157580038:
      return "Dari"
    case 157580007:
      return "Bengalsk"
    case 157580039:
      return "Somali"
    case 157580008:
      return "Dansk"
    case 157580040:
      return "Litauisk"
    case 157580009:
      return "Engelsk"
    case 157580010:
      return "Finsk"
    case 157580011:
      return "Fler, Språk"
    case 157580012:
      return "Fransk"
    case 157580013:
      return "Gresk, Moderne"
    case 157580014:
      return "Italiensk"
    case 157580015:
      return "Japansk"
    case 157580016:
      return "Kinesis, kKantonesisk"
    case 157580017:
      return "Nederlandsk"
    case 157580018:
      return "Polsk"
    case 157580019:
      return "Portugisisk"
    case 157580020:
      return "Russisk"
    case 157580021:
      return "Samisk"
    case 157580022:
      return "Spansk"
    case 157580023:
      return "Svensk"
    case 157580024:
      return "Tyrkisk"
    case 157580025:
      return "Tysk"
    case 157580026:
      return "Vietnamesisk"
    case 157580027:
      return "Arabisk"
    case 157580028:
      return "Flamsk"
    case 157580029:
      return "Islandsk"
    case 157580030:
      return "Kroatisk"
    case 157580031:
      return "Persisk"
    default:
      return "Bokmål"
  }
}
