import React, { useEffect, useState } from "react"
import { fetchApi } from "../Common/WebApi-utils"
import LoadingModal from "../Modals/LoadingModal"
import { GetLanguageName } from "../Common/Utils"
import MyAssessmentCopyData from "./MyAssessmentCopyData"
import MessageModal from "../Modals/MessageModal"

/**
 * This component is for listing my ordered materials from Dynamics365
 *
 * @version 1.0.1
 * @visibleName My Assessment Copy list
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const MyAssessmentCopy = ({ accessToken, location }) => {
  const [loading, setLoading] = useState(false)
  const [material, setMaterial] = useState([])
  const [buttonName, setButtonName] = useState("Vis mer")
  const [size, setSize] = useState(3)

  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  useEffect(() => {
    setLoading(true)
    fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalOrderLines/me", accessToken, location)
      .then((response) => {
        setMaterial(response.data)
      })
      .catch((error) => {
        setErrorInfo(() => ({
          buttonText: "Ok",
          title: "Oops!",
          message: error.message,
          email: false,
          showError: true,
        }))
      })
      .finally(function () {
        setLoading(false)
      })
  }, [])

  const onResize = () => {
    if (size === 3) {
      setButtonName("Vis mindre")
      setSize(material.length)
    } else {
      setButtonName("Vis mer")
      setSize(3)
    }
  }

  return (
    <div className="bg-white rounded-lg p-4 border-blue">
      {errorInfo.showError && <MessageModal {...errorInfo} />}
      {loading ? <LoadingModal /> : null}
      <div className="relative lg:pb-2">
        <h2 className="text-2xl font-bold">Mine vurderingseksemplarer</h2>
      </div>
      <ul>{material.map((data) => <MyAssessmentCopyData key={data.id} data={data} />).slice(0, size)}</ul>
      {material.length <= 3 ? null : (
        <div className="flex justify-center pt-2 border-none">
          <button
            className="justify-center rounded border-none font-semibold hover:bg-gray-200 px-2 focus:ring-transparent"
            onClick={onResize}
          >
            {buttonName}
          </button>
        </div>
      )}
    </div>
  )
}

export default MyAssessmentCopy
