import React, { useEffect, useState } from "react"
import { fetchApi } from "../Common/WebApi-utils"
import { GetLanguageName } from "../Common/Utils"
import MessageModal from "../Modals/MessageModal"

/**
 * This component is for showing my ordered material data from Dynamics365
 * @remark This data is listed in the component "MyAssessmentCopy"
 *
 * @version 1.0.1
 * @visibleName My Assessment Copy list Data
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const MyAssessmentCopyData = ({ data }) => {
    const [material, setMaterial] = useState({
        id: "",
        iSBN: "",
        name: "",
        subtitle: "",
        vareEierStatus: "",
        language: "",
    })

    const [errorInfo, setErrorInfo] = useState({
        title: "",
        message: "",
        buttonText: "",
        email: false,
        showError: false,
    })

    useEffect(() => {
        const materialId = data.materialId
        fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalMaterials/" + materialId)
            .then((response) => {
                setMaterial((prevState) => ({
                    ...prevState,
                    id: response.data.id,
                    iSBN: response.data.iSBN,
                    name: response.data.name,
                    subtitle: response.data.subtitle,
                    vareEierStatus: response.data.vareEierStatus,
                    language: response.data.language,
                }))
            })
            .catch((error) => {
                setErrorInfo(() => ({
                    buttonText: "Ok",
                    title: "Oops!",
                    message: error.message,
                    email: false,
                    showError: true,
                }))
            })
            .finally(() => {
                //allways executed
            })
    }, [])
    return (
        <li>
            {errorInfo.showError && <MessageModal {...errorInfo} />}
            <div className="relative pb-4 border-b space-y-4">
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                <div className="relative flex space-x-3 justify-between">
                    <div>
                        <span className="block font-semibold text-md">{material.name}</span>
                        <span className="block text-sm">{material.subtitle}</span>
                        <span className="block text-sm pt-4">
                            ISBN: {material.iSBN}
                            {/* <NumberFormat value={material.iSBN} displayType={"text"} format="### ## ## ### ### ### ### ###" /> */}
                        </span>
                    </div>

                    <div className="text-right text-sm">
                        <span className="block text-sm text-right">{GetLanguageName(material.language)}</span>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default MyAssessmentCopyData
