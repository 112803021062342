import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { redirect } from "../Common/Utils"

/**
 * This component is the error message modal
 *
 * @version 1.0.1
 * @visibleName Error message modal
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */

type MessageModalProps = {
    title?: string
    message?: string
    buttonText?: string
    email?: boolean
}

export default function MessageModal(props: MessageModalProps) {
    const [open, setOpen] = useState(true)

    const OpenEmail = () => {
        window.open('mailto:support@gyldendal.no')
        redirect()
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" static className="absolute z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="flex items-center justify-center min-h-screen">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </div>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        {props.title}{" "}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">{props.message}{" "}</p>
                                    </div>
                                </div>
                            </div>
                            {props.email && (
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-carmine-500 text-base font-medium text-black hover:bg-carmine-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-carmine-500 sm:text-sm"
                                        onClick={() => OpenEmail()}
                                    >
                                        {props.buttonText}
                                    </button>
                                </div>
                            )}
                            {!props.email && (
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-carmine-500 hover:bg-carmine-200 text-white hover:text-black border-carmine-500 text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-carmine-500 sm:text-sm"
                                        onClick={() => setOpen(false)}
                                    >
                                        {props.buttonText}
                                    </button>
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
