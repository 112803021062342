import axios from "axios"
import { navigate } from "gatsby"
import { redirect } from "./Utils"

/**
 * This component is for fetching and upserting data from and inn to Dynamics365 and from NSK
 *
 * @param {[]} endpointurl and params
 * @returns {[]} returns an api response
 * @version 1.0.1
 * @visibleName WebApi
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */

export const fetchNSKApi = async (nsknr) => {
  const response = axios.get(process.env.GATSBY_API_NSK + nsknr, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  return response
}

export const checkExpireDate = (location) => {
  let isNavigation = false
  const tokenexpireRaw = window.sessionStorage.getItem("gyldendal_minside_access_token_expire")

  const tokenexpire = new Date(tokenexpireRaw)
  const tokenexpireMs = tokenexpire.getTime()

  const isoTime = new Date().toISOString()
  const dtNow = new Date(isoTime)
  const dtNowMs = dtNow.getTime()

  const expirationDtMs = tokenexpireMs - 60 * 2000

  const productId = window.sessionStorage.getItem("gyldendal_minside_productid")

  if (tokenexpireRaw === "" || tokenexpireRaw === "undefined" || tokenexpireRaw === null) {
    isNavigation = true
    navigate(process.env.GATSBY_API_URL_ID + "/TibetIdentity?location=" + location + "&productid=" + productId)
  }

  if (dtNowMs > expirationDtMs) {
    isNavigation = true
    navigate(process.env.GATSBY_API_URL_ID + "/TibetIdentity?location=" + location + "&productid=" + productId)
  }

  return isNavigation
}

export const fetchApi = async (url, accessToken, location): Promise<any> => {
  if (accessToken === null || accessToken === undefined || accessToken === "")
    accessToken = window.sessionStorage.getItem("gyldendal_minside_access_token")
  checkExpireDate(location)

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      Authorization: accessToken,
    },
  })

  return response
}

export const fetchApiAnonymous = (url) => {
  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
    },
  })

  return response
}

export const fetchApiInterest = async (url, accessToken, location) => {
  if (accessToken === "CMS") {
    const response = axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      },
    })

    return response
  }

  if (accessToken === null || accessToken === undefined || accessToken === "")
    accessToken = window.sessionStorage.getItem("gyldendal_minside_access_token")
  checkExpireDate(location)

  const response = axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      Authorization: accessToken,
    },
  })

  return response
}

export const postApi = async (url, obj, location) => {
  checkExpireDate(location)
  const response = axios.post(url, obj, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      Authorization: window.sessionStorage.getItem("gyldendal_minside_access_token"),
    },
  })

  return response
}

export const postApiAnonymous = (url, obj) => {
  const response = axios.post(url, obj, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
    },
  })

  return response
}

export const postApiNewsLetter = async (url, obj, accessToken, location) => {
  if (accessToken === null || accessToken === undefined || accessToken === "")
    accessToken = window.sessionStorage.getItem("gyldendal_minside_access_token")
  checkExpireDate(location)

  const response = axios.post(url, obj, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      Authorization: accessToken,
    },
  })

  return response
}

export const deleteApiNewsLetter = async (url, accessToken, location) => {
  if (accessToken === null || accessToken === undefined || accessToken === "")
    accessToken = window.sessionStorage.getItem("gyldendal_minside_access_token")
  checkExpireDate(location)

  const response = axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      Authorization: accessToken,
    },
  })

  return response
}

export const deleteApiNewsLetterEmail = async (url, accessToken, location) => {
  if (accessToken === null || accessToken === undefined || accessToken === "")
    accessToken = window.sessionStorage.getItem("gyldendal_minside_access_token")
  const isNavigation = checkExpireDate(location)

  if (!isNavigation) {
    const response = axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
        Authorization: accessToken,
      },
    })

    return response
  }

  return null
}

export const deleteApiNewsLetterCMS = async (url) => {
  const response = axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
    },
  })

  return response
}

export const postApiNewsLetterCMS = async (url, obj) => {
  const response = axios.post(url, obj, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
    },
  })

  return response
}

export const patchApi = async (url, obj, location) => {
  checkExpireDate(location)
  const response = axios.patch(url, obj, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      Authorization: window.sessionStorage.getItem("gyldendal_minside_access_token"),
    },
  })

  return response
}

export const patchApiNewsLetter = async (url, obj, accessToken, location) => {
  if (accessToken === null || accessToken === undefined || accessToken === "")
    accessToken = window.sessionStorage.getItem("gyldendal_minside_access_token")
  checkExpireDate(location)

  const response = axios.patch(url, obj, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      Authorization: accessToken,
    },
  })

  return response
}

export const patchApiInterest = async (url, obj, accessToken, location) => {
  if (accessToken === "CMS") {
    const response = axios.patch(url, obj, {
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      },
    })

    return response
  }

  if (accessToken === null || accessToken === undefined || accessToken === "")
    accessToken = window.sessionStorage.getItem("gyldendal_minside_access_token")
  checkExpireDate(location)

  const response = axios.patch(url, obj, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
      Authorization: accessToken,
    },
  })

  return response
}

export const patchApiAnonymous = (url, obj) => {
  const response = axios.patch(url, obj, {
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_API_KEY,
    },
  })

  return response
}
