import React, { useState, useRef, useMemo, useEffect, StrictMode } from "react"
import { navigate, Link } from "gatsby"
import SearchableDropDown from "../DropDowns/SearchableDropdown"
import { EmailSupportFeide, EmailSupportTibet, EmailSupportTibetEdit } from "../Common/InfoText"

import CancelModal from "../Modals/CancelModal"
import { fetchApi, fetchNSKApi, patchApi, postApi } from "../Common/WebApi-utils"
import { cancelMethod, validatePhone, onVerifyName } from "../Common/Utils"
import { SaveButton } from "../Common/Buttons"

import SEO from "../SEO/SEO"
import queryString from "query-string"
import LoadingModal from "../Modals/LoadingModal"

import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"

import NewEmail from "../Common/NewEmail"

import { CountriesToValidate } from "../Common/CountriesToValidate"
import NumberFormat from "react-number-format"
import MessageModal from "../Modals/MessageModal"
import { closeModal, HandleFeideSchool, HandleUserAccountMemo, HandleUserInfoMemo, HandlUserInfoVerify, updateInfo, updateSchool } from "../Common/UserApiUtils"
import FeideMessageModal from "../Modals/FeideMessageModal"
import FeideSchoolControl from "../DropDowns/FeideSchoolControl"

/**
 * This component is for user managment
 * Modify and validate the contact information in Dynamics365
 *
 * @version 1.0.1
 * @visibleName User Info
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */

//TODO: comment out in production. For testing Feide user
// const schoolId = "NO975270866"
let queryParam

const UserInfo = ({ user, account }) => {
  const [isCheck, setCheck] = useState(false)
  const [userType, setUserType] = useState("")
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sampleAccess, setSampleAccess] = useState(false)
  const [editMode, setEditMode] = useState("default")

  const inputRef = useRef({})
  const [isEmailValid, setEmailValid] = useState(false)
  const [newUser, setNewUser] = useState(false)

  const [newAccountId, setNewAccountId] = useState()
  const [phoneValue, setPhoneValue] = useState("")
  const [isPhoneValid, setPhoneValid] = useState(false)
  const [isNameValid, setNameValid] = useState(false)
  const [showError, setShowError] = useState(false)

  const [userInfo, setUserInfo] = useState({
    emailaddress1: "",
    firstname: "",
    lastname: "",
    fullname: "",
    mobilephone: "",
    emailVerifiedOn: "",
    mobileVerifiedDate: "",
    profileverifiedon: "",
    parentAccount: "",
  })

  const [accountInfo, setAccountInfo] = useState({
    accountid: "",
    accountname: "",
    address: "",
    zipCode: "",
    city: "",
    orgnr: "",
  })

  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  useEffect(() => {
    setLoading(true)
    setNewUser(false)
    setEditMode("default")

    if (location.search !== null) queryParam = queryString.parse(location.search)
    setUserType(window.sessionStorage.getItem("gyldendal_minside_usertype"))
    window.sessionStorage.getItem("gyldendal_minside_producturl") ? setSampleAccess(true) : setSampleAccess(false)

    if (!queryParam.schoolNr && queryParam.usertype === "feide") setShowError(true)

    //Get school from NSR and check if the school is the same on the user in Dynamics365, if not update or create new if none exsisting.
    // if (queryParam.schoolNr && queryParam.usertype === "feide") {
    //   //TODO: change schoolId with queryParam.schoolNr in production
    //   HandleFeideSchool(queryParam, setFeideSchools, setNewAccountIds, setErrorInfo)
    // }

    setLoading(false)
  }, [])

  useMemo(() => {
    HandleUserInfoMemo(setUserInfo, user, setPhoneValue)
  }, [user])

  useMemo(() => {
    HandleUserAccountMemo(setAccountInfo, account)
  }, [account])

  //Update last verified date in Dynamics365
  const onVerify = async () => {
    await closeModal(setErrorInfo)
    if (userInfo.mobilephone === undefined || userInfo.mobilephone == null) {
      setErrorInfo(() => ({
        buttonText: "Ok",
        title: "Oops!",
        message: "Vennligst tast inn mobilnummeret!",
        email: false,
        showError: true,
      }))
      return
    }

    if (userInfo.emailaddress1 === undefined || userInfo.emailaddress1 == null || userInfo.emailaddress1 === '') {
      setErrorInfo(() => ({
        buttonText: "Ok",
        title: "Oops!",
        message: "Vennligst tast inn eposten!",
        email: false,
        showError: true,
      }))
      return
    }

    if (userType !== "feide") {
      const accountId = window.sessionStorage.getItem("gyldendal_minside_accountid")
      if (accountId === null || accountId === "" || accountId === "undefined") {
        setErrorInfo(() => ({
          buttonText: "Ok",
          title: "Oops!",
          message: "Vennligst velg skole!",
          email: false,
          showError: true,
        }))
        return
      }
    }

    if (userType === "feide") {
      console.log("Verifiy Feide school")
      console.log(newAccountId)
      if (newAccountId === null || newAccountId === "" || newAccountId === "undefined" || newAccountId === undefined) {
        setErrorInfo(() => ({
          buttonText: "Ok",
          title: "Oops!",
          message: "Vennligst velg skole!",
          email: false,
          showError: true,
        }))
        return
      }
    }

    HandlUserInfoVerify(userInfo, setErrorInfo, newAccountId, userType)

    if (sampleAccess) {
      navigate("/app/provetilgang")
    } else return navigate("/app/vurderingseksemplar")
  }

  //When matching a new user
  const onPhoneClick = () => {
    updateInfo("phone", phoneValue, setUserInfo, setAccountInfo, setEditMode, userInfo)
    setPhoneValid(false)
  }

  return (
    <div aria-labelledby="userprofile" className="mx-auto lg:max-w-2xl sm:max-w-sm">
      {showError && <FeideMessageModal />}
      <SEO title="Bekreft opplysninger" description="Gyldendal sin hjem side." />
      {errorInfo.showError && <MessageModal {...errorInfo} />}
      {loading && <LoadingModal />}
      <div className="mx-auto bg-white px-8 py-5 rounded-lg justify-self-center bg-white-500">
        <div className="mt-6 flow-root">
          <h1 className="text-3xl font-bold pb-5">Bekreft opplysningene</h1>
          <span className="text-md pb-5">
            Før du kan gå videre ønsker vi at du ser over kontaktopplysningene dine. Dersom de ikke stemmer, må du
            oppdatere informasjonen før du bekrefter nederst på siden.
          </span>
          <ul className="">
            <div className="">
              <div className="">
                <li>
                  <div className="relative space-y-6">
                    <span className="absolute top- left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                    <h2 className="text-md font-semibold">Navn</h2>

                    {editMode === "name" ? (
                      <div className="lg:flex lg:space-x-3 lg:space-y-0 space-y-">
                        <div className="lg:space-x-3 lg:space-y-0 lg:flex md:space-y-3 space-y-3">
                          <input
                            ref={(el) => (inputRef.current["first_name"] = el)}
                            defaultValue={userInfo.firstname}
                            className="rounded text-md h-10 border-black border px-3 lg:w-min md:w-full w-full "
                            id="firstNameInput"
                            type="text"
                            onChange={(e) => onVerifyName(e.target.value, setNameValid)}
                            autoComplete="given-name"
                            placeholder="fornavn"
                            autoFocus
                          />
                          <input
                            ref={(el) => (inputRef.current["last_name"] = el)}
                            defaultValue={userInfo.lastname}
                            className="rounded text-md h-10 border-black border pr-3 lg:w-min md:w-full w-full "
                            id="lastNameInput"
                            type="text"
                            autoComplete="family-name"
                            onChange={(e) => onVerifyName(e.target.value, setNameValid)}
                            placeholder="etternavn"
                          />
                        </div>
                        <div className="space-x-3 text-right">
                          <button
                            onClick={() => setEditMode("default")}
                            className="h-9 text-black hover:text-gray border-black w-15 border-b"
                          >
                            Avbryt
                          </button>
                          {isNameValid && (
                            <SaveButton
                              id="saveName"
                              onClick={() => {
                                updateInfo(
                                  "name",
                                  inputRef.current["first_name"].value + ":" + inputRef.current["last_name"].value,
                                  setUserInfo,
                                  setAccountInfo,
                                  setEditMode,
                                  userInfo
                                )
                                window.sessionStorage.setItem(
                                  "gyldendal_userfullname",
                                  inputRef.current["first_name"].value + " " + inputRef.current["last_name"].value
                                )
                                setNameValid(false)
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="relative flex space-x-5">
                        <p className="border-none text-md min-w-10">{userInfo.fullname}</p>
                        <span
                          className={
                            newUser
                              ? "invisible"
                              : "text-md text-indigo-500 h-6 border-indigo-500 border-b cursor-pointer"
                          }
                          onClick={() => setEditMode("name")}
                        >
                          Endre
                        </span>
                        <span />
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <div className="relative space-y-6">
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                    <h2 className="text-md font-semibold">Mobil*</h2>

                    {(editMode === "phone" || newUser) && (
                      <div className="space-x-4 lg:space-x-3 lg:space-y-0 lg:flex md:space-y-3 space-y-3 ">
                        <div className="pr-4">
                          <PhoneInput
                            // autoFormat={true}
                            inputProps={{
                              name: "phoneInput",
                            }}
                            inputStyle={{ maxHeight: "15px", width: "100%" }}
                            country={"no"}
                            onlyCountries={CountriesToValidate}
                            countryCodeEditable={false}
                            value={phoneValue}
                            onChange={(e) => setPhoneValue(e)}
                            isValid={(inputNumber) => validatePhone(inputNumber)}
                          />
                        </div>
                        <div className="space-x-3 text-right pr-4">
                          {!newUser && (
                            <button
                              onClick={() => setEditMode("default")}
                              className="h-9 text-black hover:text-gray px-2 border-black w-15 border-b"
                            >
                              Avbryt
                            </button>
                          )}
                          {validatePhone(phoneValue) ? (
                            <button
                              onClick={onPhoneClick}
                              className="h-10 bg-carmine-500 hover:bg-carmine-700 text-white font-semibold hover:text-white px-4 border border-red-500 rounded"
                            >
                              Lagre
                            </button>
                          ) : null}
                        </div>
                      </div>
                    )}

                    {editMode !== "phone" && !newUser && (
                      <div className="relative flex space-x-5">
                        <p className="border-none px-0 text-md lg:min-w-20">
                          {/* {userInfo.mobilephone} */}
                          <NumberFormat value={userInfo.mobilephone} displayType={"text"} format="+## ### ## ###" />
                        </p>
                        <span
                          className="text-md text-indigo-500 h-6 px-0 border-indigo-500 w-12 border-b cursor-pointer"
                          onClick={() => setEditMode("phone")}
                        >
                          Endre
                        </span>
                        <span />
                      </div>
                    )}
                  </div>
                  {newUser && <p className="text-sm italic p-2 text-gray-500">*Mobilnummer er påkrevd å fylle ut</p>}
                </li>
                <li>
                  <NewEmail
                    editMode={editMode}
                    setEditMode={setEditMode}
                    userType={userType}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    setAccountInfo={setAccountInfo}
                    newUser={newUser}
                  />
                </li>
                <li>
                  <div className={newUser ? "relative space-y-6 invisible" : "relative space-y-6"}>
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                    <h2 className="text-md font-semibold">Skole</h2>
                    <div className="relative flex justify-between">
                      {editMode === "school" && userType === "tibet" && (
                        <div>
                          <div className="space-x-4 lg:space-x-3 lg:space-y-0 lg:flex space-y-3 ">
                            <div>
                              <SearchableDropDown accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                            </div>
                            <div className="text-right space-x-3">
                              <button
                                onClick={() => cancelMethod(setAccountInfo, account, setEditMode)}
                                className="h-9 text-black hover:text-gray px-2 border-black w-15 border-b"
                              >
                                Avbryt
                              </button>
                              <button
                                onClick={() => updateSchool(accountInfo, setEditMode, setErrorInfo)}
                                className="h-10 w-15 bg-carmine-500 hover:bg-carmine-700 text-white font-semibold hover:text-white px-4 border border-red-500 rounded"
                              >
                                Lagre
                              </button>
                            </div>
                          </div>
                          <EmailSupportTibetEdit />
                        </div>
                      )}
                      {editMode !== "school" && userType === "feide" && (
                        <FeideSchoolControl setNewAccountId={setNewAccountId} queryParam={queryParam} />
                      )}
                      {editMode !== "school" && userType === "tibet" && (
                        <div>
                          <div className="relative flex space-x-5 justify-between">
                            <div className="space-y-2">
                              <p className="border-none px-0 text-md">{accountInfo.accountname}</p>
                              <p className="border-none px-0 text-md">{accountInfo.address}</p>
                              <p className="border-none px-0 text-md">{accountInfo.zipCode} {accountInfo.city}</p>
                            </div>
                            <span
                              className="text-md text-indigo-500 h-6 px-0 border-indigo-500 w-12 border-b cursor-pointer"
                              onClick={() => setEditMode("school")}
                            >
                              Endre
                            </span>
                            <span />
                          </div>
                          <EmailSupportTibet />
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              </div>

              <li className={newUser ? "invisible" : ""}>
                <div className="flex mt-6 pb-8 ">
                  <label className="flex items-center space-x-6">
                    <input
                      type="checkbox"
                      className="form-checkbox h-7 w-7 text-carmine-500 border-carmine-500 rounded  focus:ring-transparent"
                      onChange={() => {
                        setCheck(!isCheck)
                      }}
                    />
                    <span className="ml-2">Jeg bekrefter at de registrerte opplysningene er korrekte</span>
                  </label>
                </div>
              </li>
            </div>
            {modal ? <CancelModal proceed={() => setModal(false)} /> : null}

            <li className={newUser ? "invisible" : ""}>
              <div className="flex justify-end py-4">
                <button
                  onClick={() => setModal(true)}
                  className="h-10 m-2 underline bg-transparent text-black hover:text-gray  px-6 border-none"
                >
                  Avbryt
                </button>
                <button
                  onClick={onVerify}
                  disabled={isCheck ? false : true}
                  className="h-10 m-2 bg-carmine-500 hover:bg-carmine-800 text-white font-semibold hover:text-white py-2 px-6 border border-carmine-500 rounded disabled:opacity-50 disabled:cursor-default"
                >
                  Gå videre
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default UserInfo
