import React, { useEffect, useMemo, useRef, useState } from "react"
import PhoneInput from "react-phone-input-2"
import { CountriesToValidate } from "../Common/CountriesToValidate"
import { onValidateEmail, validatePhoneMiniUser } from "../Common/Utils"
import { patchApiNewsLetter } from "../Common/WebApi-utils"
import LoadingModal from "../Modals/LoadingModal"

/**
 * This component is for showing and editing the user data from Dynamics365
 * @param user
 * @param accessToken
 * @param location
 * @returns
 */
const MarketingUserProfile = ({ setUser, user, accessToken, location }) => {
  const [editMode, setEditMode] = useState("default")
  const [loading, setLoading] = useState(false)
  const inputRef = useRef({})
  const [phoneValue, setPhoneValue] = useState("")
  const [isPhoneValid, setPhoneValid] = useState(false)
  const [emailFormatValid, setEmailFormatValid] = useState(false)
  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  useEffect(() => {
    // setPhoneValue(user.mobilephone)
  }, [])

  const HandleMiniUserInfoMemo = (setPhoneValue, user) => {
    setPhoneValue(user.mobilephone)
  }

  useMemo(() => {
    HandleMiniUserInfoMemo(setPhoneValue, user)
  }, [user])


  const onEdit = () => {
    setEditMode("edit")
  }
  const onCancel = () => {
    setEditMode("default")
  }

  const onSend = () => {
    setLoading(true)
    const fnameValue =
      inputRef.current["firstname"].value === "" ? user.fullname : inputRef.current["firstname"].value
    const lnameValue =
      inputRef.current["lastname"].value === "" ? userInfo.lastname : inputRef.current["lastname"].value
    const emailValue =
      inputRef.current["email"].value === "" ? user.email : inputRef.current["email"].value
    const mobilephoneValue = isPhoneValid ? phoneValue : user.mobilephone

    const userObj = {
      email: emailValue,
      mobilephone: mobilephoneValue,
      firstname: fnameValue,
      lastname: lnameValue,
    }

    patchApiNewsLetter(process.env.GATSBY_API_URL_APIM + "/GyldendalContacts", userObj, accessToken, location)
      .then((response) => {
        setUser((prevState) => ({
          ...prevState,
          firstname: fnameValue,
          lastname: lnameValue,
          fullname: fnameValue + " " + lnameValue,
          mobilephone: mobilephoneValue,
          emailaddress1: emailValue,
        }))

        setEditMode("default")
        window.sessionStorage.setItem("gyldendal_minside_userfullname", response.data.fullname)
        if (response.data.account !== undefined) {
          window.sessionStorage.setItem("gyldendal_minside_accountid", response.data.account.id)
          window.sessionStorage.setItem("gyldendal_minside_crmorgid", response.data.account.organizationNumber)
          window.sessionStorage.setItem("gyldendal_minside_accountname", response.data.account.name)
        }
      })
      .catch((_error) => {
        setErrorInfo(() => ({
          buttonText: "Ok",
          title: "Oops!",
          message: "Får ikke lastet inn data",
          email: false,
          showError: true,
        }))
      })
      .finally(function () {
        setLoading(false)
      })
  }

  return (
    <div className="w-full">
      {loading && <LoadingModal />}
      {editMode === "edit" ? (
        <div className="flex flex-col space-y-2 items-start justify-start">
          <div className="flex flex-col space-y-5 items-start justify-between p-6 bg-gray-50 border rounded border-gray-300 w-full">
            <p className="text-lg font-semibold leading-normal text-gray-600">Kontaktinformasjon</p>
            <div className="flex flex-col space-y-1 items-start justify-start w-full">
              <p className="text-sm font-semibold leading-tight text-gray-600">Fornavn</p>
              <input
                ref={(el) => (inputRef.current["firstname"] = el)}
                defaultValue={user.firstname}
                className="text-base leading-normal text-gray-600 w-full px-3 py-2 bg-white shadow border rounded-md border-gray-300"
                placeholder="Ola Normann"
              ></input>
            </div>
            <div className="flex flex-col space-y-1 items-start justify-start w-full">
              <p className="text-sm font-semibold leading-tight text-gray-600">Etternavn</p>
              <input
                ref={(el) => (inputRef.current["lastname"] = el)}
                defaultValue={user.lastname}
                className="text-base leading-normal text-gray-600 w-full px-3 py-2 bg-white shadow border rounded-md border-gray-300"
                placeholder="Ola Normann"
              ></input>
            </div>
            <div className="flex flex-col space-y-1 items-start justify-start w-full">
              <p className="text-sm font-semibold leading-tight text-gray-600">E-post</p>
              <input
                ref={(el) => (inputRef.current["email"] = el)}
                defaultValue={user.email}
                type="email"
                onChange={(e) => onValidateEmail(e.target.value, setEmailFormatValid)}
                className="text-base leading-normal text-gray-600 w-full px-3 py-2 bg-white shadow border rounded-md border-gray-300"
                placeholder="ola.normann@ammerudskole.no"
              ></input>
            </div>
            <div className="flex flex-col space-y-1 items-start justify-start w-full">
              <p className="text-sm font-semibold leading-tight text-gray-600">Telefonnummer</p>
              <PhoneInput
                // autoFormat={true}
                inputProps={{
                  name: "phoneInput",
                }}
                inputStyle={{ maxHeight: "15px", width: "100%" }}
                country={"no"}
                onlyCountries={CountriesToValidate}
                countryCodeEditable={false}
                value={phoneValue}
                onChange={(e) => setPhoneValue(e)}
                isValid={(inputNumber) => validatePhoneMiniUser(inputNumber, setPhoneValid)}
              />
            </div>
            <div className="flex flex-col space-y-5 items-start justify-start w-full">
              <div className="bg-gray-200" />
              <div className="inline-flex space-x-3 items-center justify-start w-full">
                <button
                  onClick={() => onSend()}
                  className="flex items-center justify-center px-4 py-2 bg-red-600 shadow rounded-md w-full hover:bg-pink-700"
                >
                  <div className="text-sm font-semibold leading-tight text-white w-full">Lagre endringer</div>
                </button>
                <button
                  onClick={() => onCancel()}
                  className="flex items-center justify-center px-4 py-2 bg-white shadow border rounded-md border-gray-300 w-full hover:bg-gray-50"
                >
                  <div className="text-sm font-semibold leading-tight text-gray-700">Angre</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-2 items-start justify-start w-full">
          <div className="inline-flex space-x-6 items-start justify-between p-6 bg-gray-50 border rounded border-gray-300 w-full">
            <div className="inline-flex flex-col space-y-2 items-start justify-start">
              <p className="text-lg font-semibold leading-normal text-gray-600">Kontaktinformasjon</p>
              <p className="text-sm leading-tight text-gray-600">{user.fullname}</p>
              <p className="text-sm leading-tight text-gray-600">{user.email}</p>
              <p className="text-sm leading-tight text-gray-600">{user.mobilephone}</p>
            </div>
            <button
              onClick={() => onEdit()}
              className="flex items-center justify-center px-4 py-2 bg-white shadow border rounded-md border-gray-300 hover:bg-gray-50"
            >
              <div className="text-sm font-semibold leading-tight text-gray-700">Endre</div>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default MarketingUserProfile
