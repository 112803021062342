import React from "react"

const SchoolRadioBox = ({ NSRFeideSchools, selectOption }) => {
    return (
        <>
            <div className="flex flex-col items-start justify-start w-full">
                <span className="text-md pb-5">Velg skole du ønsker at vurderingseksemplaret blir sendt til nedenfor. </span>
                <div className="flex flex-col space-y-4 items-center justify-start w-full">
                    {NSRFeideSchools.map(NSRFeideSchool => (
                        <div className="flex flex-col items-center justify-end w-full">
                            <div className="inline-flex space-x-3 items-start justify-start p-4 bg-white border rounded-tl-md rounded-tr-md border-gray-200 w-full">

                                <input
                                    id={NSRFeideSchool.organizationNumber}
                                    name="NSRFeideSchool"
                                    type="radio"
                                    onChange={e => selectOption(e.target.id)}
                                    defaultChecked={NSRFeideSchool.organizationNumber === "0"}
                                    className="focus:ring-meny-700 h-4 w-4 text-pink-700 border-red-300"
                                />
                                <div className="inline-flex flex-col items-start justify-start">
                                    <p className="text-sm font-semibold leading-tight text-gray-900 w-full">{NSRFeideSchool.name}</p>
                                    <p className="text-sm leading-tight text-gray-500 w-full">{NSRFeideSchool.address}. {NSRFeideSchool.zipCode}{" "}{NSRFeideSchool.city}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>

    )
}

export default SchoolRadioBox
