import React, { useEffect, useState } from "react"
import { fetchApi } from "../Common/WebApi-utils"
import Moment from "react-moment"
import "moment-timezone"
import MySampleAccessData from "./MySampleAccessData"
import MessageModal from "../Modals/MessageModal"

/**
 * This component is for listing my ordered Tibet accesses from Dynamics365
 *
 * @version 1.0.1
 * @visibleName My Assessment Copy list
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const MySampleAccess = ({ accessToken, location }) => {
  const [tibetAccess, setTibetAccess] = useState([])
  const [buttonName, setButtonName] = useState("Vis mer")
  const [size, setSize] = useState(3)

  const [errorInfo, setErrorInfo] = useState({
    title: "",
    message: "",
    buttonText: "",
    email: false,
    showError: false,
  })

  useEffect(() => {
    fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalTibetAccesses/me", accessToken, location)
      .then((response) => {
        setTibetAccess(response.data)
      })
      .catch((error) => {
        setErrorInfo(() => ({
          buttonText: "Ok",
          title: "Oops!",
          message: error.message,
          email: false,
          showError: true,
        }))
      })
      .finally(function () {
        //always executed
      })
  }, [])

  const onResize = () => {
    if (size === 3) {
      setButtonName("Vis mindre")
      setSize(tibetAccess.length)
    } else {
      setButtonName("Vis mer")
      setSize(3)
    }
  }

  return (
    <div className="bg-white rounded-lg p-4 border-blue">
      {errorInfo.showError && <MessageModal {...errorInfo} />}
      <div className="relative lg:pb-2">
        <h2 className="text-2xl font-bold">Mine prøvetilganger </h2>
      </div>
      <ul>{tibetAccess.map((data) => <MySampleAccessData key={data.id} data={data} />).slice(0, size)}</ul>
      {tibetAccess.length <= 3 ? null : (
        <div className="flex justify-center pt-2 border-none">
          <button
            className="justify-center rounded border-none font-semibold hover:bg-gray-200 px-2 focus:border-none"
            onClick={onResize}
          >
            {buttonName}
          </button>
        </div>
      )}
    </div>
  )
}

export default MySampleAccess
